import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { BASE_URL } from "@/main";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    VAT: 7.5,
    minimumWage: 30000,
    liveVersion: true,
    paystackKey: "pk_live_79f2d70d9f73c2d296b5cfb45a133c13f1f259d6", // "pk_test_7e6c3e35412ac1c042ecbcde637c29e21590e74b"
    flutterwavePaymentKey: "FLWSECK_TEST-daae88ea78dba95a2e02372ed361cbb3-X",
    captchaSiteKey: "6LeZn94pAAAAADdPxSoo4zC7BGpkyEAVUpaIQ1aE",
    weekDays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    userToken: localStorage.getItem("userToken") || null,
    status: "",
    userData: localStorage.getItem("userData") || "",
    companyData: localStorage.getItem("companyData") || "",
    employeeDataForCompany:
      localStorage.getItem("employeeDataForCompany") || "",
    companyServices: localStorage.getItem("companyServices") || "",
    companyEmployees: localStorage.getItem("companyEmployees") || "",
    companyAppraisals: localStorage.getItem("companyAppraisals") || "",
    companyDepartments: localStorage.getItem("companyDepartments") || "",
    companyKRAs: localStorage.getItem("companyKRAs") || "",
    companyGoals: localStorage.getItem("companyGoals") || "",
    companyJobCategories: localStorage.getItem("companyJobCategories") || "",
    companyCandidates: localStorage.getItem("companyCandidates") || "",
    subscription_expired: localStorage.getItem("subscription_expired") || false,
    fetchEmployeeStatus: "",
    checkInStatus: parseInt(localStorage.getItem("checkInStatus")) || "",
    msTime: localStorage.getItem("msTime") || "",
    companyAssets: localStorage.getItem("companyAssets") || "",
    companyApprovedLeave: localStorage.getItem("companyApprovedLeave") || "",
    companyAllLeave: localStorage.getItem("companyAllLeave") || "",
    companyAllLeaveType: localStorage.getItem("companyAllLeaveType") || "",
    companyAllAssetType: localStorage.getItem("companyAllAssetType") || "",
    employeeDocuments: localStorage.getItem("employeeDocuments") || "",
    companyAllCheckin: localStorage.getItem("companyAllCheckin") || "",
    companyAllVendors: localStorage.getItem("companyAllVendors") || "",
    companyAllAnnouncements:
      localStorage.getItem("companyAllAnnouncements") || "",
    companyAllEmployeeAnnouncements:
      localStorage.getItem("companyAllEmployeeAnnouncements") || "",
    companyAllEvents: localStorage.getItem("companyAllEvents") || "",
    companyAllBilling: localStorage.getItem("companyAllBilling") || "",
    allRoles: localStorage.getItem("allRoles") || "",
    companyAttendanceConfig:
      localStorage.getItem("companyAttendanceConfig") || "",
    employeeTimesheets: localStorage.getItem("employeeTimesheets") || "",
    companyAllTimesheets: localStorage.getItem("companyAllTimesheets") || "",
    allAvailableServices: localStorage.getItem("allAvailableServices") || "",
    remaining_months: localStorage.getItem("remaining_months") || "",
    two_fa_available: localStorage.getItem("two_fa_available") || "",
    two_fa_confirmed: localStorage.getItem("two_fa_confirmed") || "",
    file_types: localStorage.getItem("file_types") || "",
    company_files: localStorage.getItem("company_files") || "",
    company_all_medicals: localStorage.getItem("company_all_medicals") || "",
    created_jobs: localStorage.getItem("created_jobs") || "",
    employee_travels: localStorage.getItem("employee_travels") || "",
    company_travels: localStorage.getItem("company_travels") || "",
    countries: localStorage.getItem("countries") || "",
    states: localStorage.getItem("states") || "",
    applicants: localStorage.getItem("applicants") || "",
    works: localStorage.getItem("works") || "",
    referees: localStorage.getItem("referees") || "",
    institutions: localStorage.getItem("institutions") || "",
    professionalbodies: localStorage.getItem("professionalbodies") || "",
    registerError: localStorage.getItem("registerError") || "",
    loginError: localStorage.getItem("loginError") || "",
    checkinAllTime: localStorage.getItem("checkinAllTime") || "",
    designations: localStorage.getItem("designations") || "",
    shifts: localStorage.getItem("shifts") || "",
    employeeShifts: localStorage.getItem("employeeShifts") || "",
    companyProjects: localStorage.getItem("companyProjects") || "",
    companyTasks: localStorage.getItem("companyTasks") || "",
    companyHolidays: localStorage.getItem("companyHolidays") || "",
    companyRoles: localStorage.getItem("companyRoles") || "",
    companyPermissions: localStorage.getItem("companyPermissions") || "",
    assignedPermissions: localStorage.getItem("assignedPermissions") || "",
    payCalendars: localStorage.getItem("payCalendars") || "",
    employeePayCalendars: localStorage.getItem("employeePayCalendars") || "",
    fixedAllowances: localStorage.getItem("fixedAllowances") || "",
    fixedDeductions: localStorage.getItem("fixedDeductions") || "",
    employeeTaxes: localStorage.getItem("employeeTaxes") || "",
    companyPayRun: localStorage.getItem("companyPayRun") || "",
    flexibleAllowances: localStorage.getItem("flexibleAllowances") || "",
    flexibleDeductions: localStorage.getItem("flexibleDeductions") || "",
    employeePayslipDetails:
      localStorage.getItem("employeePayslipDetails") || "",
    payrollConfig: localStorage.getItem("payrollConfig") || "",
    loggedInTime: localStorage.getItem("loggedInTime") || "",
    employeesAssignedToProjects:
      localStorage.getItem("employeesAssignedToProjects") || "",
    companyHospitals: localStorage.getItem("companyHospitals") || "",
    companyLoans: localStorage.getItem("companyLoans") || "",
    employeeLoans: localStorage.getItem("employeeLoans") || "",
    liquidationRequests: localStorage.getItem("liquidationRequests") || "",
    companyGeoLocations: localStorage.getItem("companyGeoLocations") || "",
    companyPayGrades: localStorage.getItem("companyPayGrades") || "",
    companyPayGradeHistories:
      localStorage.getItem("companyPayGradeHistories") || "",
    companyJobDocuments: localStorage.getItem("companyJobDocuments") || "",
    companyGradeLevels: localStorage.getItem("companyGradeLevels") || "",
    companyEmoluments: localStorage.getItem("companyEmoluments") || "",
    statutoryDeductions: localStorage.getItem("statutoryDeductions") || "",
    companyStatutoryDeductions:
      localStorage.getItem("companyStatutoryDeductions") || "",
    companyTaskSheets: localStorage.getItem("companyTaskSheets") || "",
    employeeTaskSheets: localStorage.getItem("employeeTaskSheets") || "",
    companyLogs: localStorage.getItem("companyLogs") || "",
    companyRequisitions: localStorage.getItem("companyRequisitions") || "",
    employeeRequisitions: localStorage.getItem("employeeRequisitions") || "",
    companyRequisitionTypes:
      localStorage.getItem("companyRequisitionTypes") || "",
    companyDockets: localStorage.getItem("companyDockets") || "",
    companyDepartmentEmployees:
      localStorage.getItem("companyDepartmentEmployees") || "",
    companyCommittees: localStorage.getItem("companyCommittees") || "",
    employeeCommittees: localStorage.getItem("employeeCommittees") || "",
    employeeDockets: localStorage.getItem("employeeDockets") || "",
    companyRequisitionComments:
      localStorage.getItem("companyRequisitionComments") || "",
    companyEmployeesInGoals:
      localStorage.getItem("companyEmployeesInGoals") || "",
    companyEmployeesInAppraisals:
      localStorage.getItem("companyEmployeesInAppraisals") || "",
    employeeKRAs: localStorage.getItem("employeeKRAs") || "",
    companyQuestionnaires: localStorage.getItem("companyQuestionnaires") || "",
    employeeAssignedGoals: localStorage.getItem("employeeAssignedGoals") || "",
    companyGoalsInAppraisals:
      localStorage.getItem("companyGoalsInAppraisals") || "",
    employeeAppraisals: localStorage.getItem("employeeAppraisals") || "",
    companyReviewers: localStorage.getItem("companyReviewers") || "",
    companyQuestionnaireResponses:
      localStorage.getItem("companyQuestionnaireResponses") || "",
    employeeAppraisalReviews:
      localStorage.getItem("employeeAppraisalReviews") || "",
    employeeGoalReviews: localStorage.getItem("employeeGoalReviews") || "",
    employeeReporters: localStorage.getItem("employeeReporters") || "",
    companyFeedbacks: localStorage.getItem("companyFeedbacks") || "",
    companyAppraisalFeedbacks:
      localStorage.getItem("companyAppraisalFeedbacks") || "",
    companyLoanRepayments: localStorage.getItem("companyLoanRepayments") || "",
    employeeLoanRepayments:
      localStorage.getItem("employeeLoanRepayments") || "",
    employeeSkillSets: localStorage.getItem("employeeSkillSets") || "",
    companySkillSets: localStorage.getItem("companySkillSets") || "",
    companyBranches: localStorage.getItem("companyBranches") || "",
    banks: localStorage.getItem("banks") || "",
    companySubaccount: localStorage.getItem("companySubaccount") || "",
    allBlogPosts: localStorage.getItem("allBlogPosts") || "",
    companyTransactions: localStorage.getItem("companyTransactions") || "",
    companyAttendanceDeductionSettings: localStorage.getItem("companyAttendanceDeductionSettings") || "",
    companyExitRequests: localStorage.getItem("companyExitRequests") || "",
    employeeExitRequests: localStorage.getItem("employeeExitRequests") || "",
    companyExitChecklists: localStorage.getItem("companyExitChecklists") || "",
    companyCourseCategories: localStorage.getItem("companyCourseCategories") || "",
    companyWorkflows: localStorage.getItem("companyWorkflows") || "",
    companyApprovalLevels: localStorage.getItem("companyApprovalLevels") || "",
    workflowServices: localStorage.getItem("workflowServices") || "",
    companyTrainingProviders: localStorage.getItem("companyTrainingProviders") || "",
    companyTrainings: localStorage.getItem("companyTrainings") || "",
    employeeTrainings: localStorage.getItem("employeeTrainings") || "",
    companyOutsourcingCompanies: localStorage.getItem("companyOutsourcingCompanies") || "",
    companyOutsourcingEmployeesByCompany: localStorage.getItem("companyOutsourcingEmployeesByCompany") || "",
    companyOutsourcingEmployeesByEmployee: localStorage.getItem("companyOutsourcingEmployeesByEmployee") || "",
    requisitionItems: localStorage.getItem("requisitionItems") || "",
  },
  getters: {
    authRequisitionItems: state => {
      if (state.requisitionItems != "") {
        return JSON.parse(state.requisitionItems);
      } else {
        return [];
      }
    },
    authCompanyOutsourcingEmployeesByEmployee: state => {
      if (state.companyOutsourcingEmployeesByEmployee != "") {
        return JSON.parse(state.companyOutsourcingEmployeesByEmployee);
      } else {
        return [];
      }
    },
    authCompanyOutsourcingEmployeesByCompany: state => {
      if (state.companyOutsourcingEmployeesByCompany != "") {
        return JSON.parse(state.companyOutsourcingEmployeesByCompany);
      } else {
        return [];
      }
    },
    authCompanyOutsourcingCompanies: state => {
      if (state.companyOutsourcingCompanies != "") {
        return JSON.parse(state.companyOutsourcingCompanies);
      } else {
        return [];
      }
    },
    authEmployeeTrainings: state => {
      if (state.employeeTrainings != "") {
        return JSON.parse(state.employeeTrainings);
      } else {
        return [];
      }
    },
    authCompanyTrainings: state => {
      if (state.companyTrainings != "") {
        return JSON.parse(state.companyTrainings);
      } else {
        return [];
      }
    },
    authCompanyTrainingProviders: state => {
      if (state.companyTrainingProviders != "") {
        return JSON.parse(state.companyTrainingProviders);
      } else {
        return [];
      }
    },
    authWorkflowServices: state => {
      if (state.workflowServices != "") {
        return JSON.parse(state.workflowServices);
      } else {
        return [];
      }
    },
    authCompanyApprovalLevels: state => {
      if (state.companyApprovalLevels != "") {
        return JSON.parse(state.companyApprovalLevels);
      } else {
        return [];
      }
    },
    authCompanyWorkflows: state => {
      if (state.companyWorkflows != "") {
        return JSON.parse(state.companyWorkflows);
      } else {
        return [];
      }
    },
    authCompanyCourseCategories: state => {
      if (state.companyCourseCategories != "") {
        return JSON.parse(state.companyCourseCategories);
      } else {
        return [];
      }
    },
    authCompanyExitChecklists: state => {
      if (state.companyExitChecklists != "") {
        return JSON.parse(state.companyExitChecklists);
      } else {
        return [];
      }
    },
    authEmployeeExitRequests: state => {
      if (state.employeeExitRequests != "") {
        return JSON.parse(state.employeeExitRequests);
      } else {
        return [];
      }
    },
    authCompanyExitRequests: state => {
      if (state.companyExitRequests != "") {
        return JSON.parse(state.companyExitRequests);
      } else {
        return [];
      }
    },
    authCompanyAttendanceDeductionSettings: state => {
      if (state.companyAttendanceDeductionSettings != "") {
        return JSON.parse(state.companyAttendanceDeductionSettings);
      } else {
        return [];
      }
    },
    authCompanyTransactions: state => {
      if (state.companyTransactions != "") {
        return JSON.parse(state.companyTransactions);
      } else {
        return [];
      }
    },
    blogPosts: state => {
      if (state.allBlogPosts != "") {
        return JSON.parse(state.allBlogPosts);
      } else {
        return [];
      }
    },
    authCompanySubaccount: state => {
      if (state.companySubaccount != "") {
        return JSON.parse(state.companySubaccount);
      } else {
        return null;
      }
    },
    allBanks: state => {
      if (state.banks != "") {
        return JSON.parse(state.banks);
      } else {
        return [];
      }
    },
    authCompanyBranches: state => {
      if (state.companyBranches != "") {
        return JSON.parse(state.companyBranches);
      } else {
        return [];
      }
    },
    authCompanySkillSets: state => {
      if (state.companySkillSets != "") {
        return JSON.parse(state.companySkillSets);
      } else {
        return [];
      }
    },
    authEmployeeSkillSets: state => {
      if (state.employeeSkillSets != "") {
        return JSON.parse(state.employeeSkillSets);
      } else {
        return [];
      }
    },
    authEmployeeLoanRepayments: state => {
      if (state.employeeLoanRepayments != "") {
        return JSON.parse(state.employeeLoanRepayments);
      } else {
        return [];
      }
    },
    authCompanyLoanRepayments: state => {
      if (state.companyLoanRepayments != "") {
        return JSON.parse(state.companyLoanRepayments);
      } else {
        return [];
      }
    },
    authCompanyAppraisalFeedbacks: state => {
      if (state.companyAppraisalFeedbacks != "") {
        return JSON.parse(state.companyAppraisalFeedbacks);
      } else {
        return [];
      }
    },
    authCompanyFeedbacks: state => {
      if (state.companyFeedbacks != "") {
        return JSON.parse(state.companyFeedbacks);
      } else {
        return [];
      }
    },
    authEmployeeReporters: state => {
      if (state.employeeReporters != "") {
        return JSON.parse(state.employeeReporters);
      } else {
        return [];
      }
    },
    authEmployeeGoalReviews: state => {
      if (state.employeeGoalReviews != "") {
        return JSON.parse(state.employeeGoalReviews);
      } else {
        return [];
      }
    },
    authEmployeeAppraisalReviews: state => {
      if (state.employeeAppraisalReviews != "") {
        return JSON.parse(state.employeeAppraisalReviews);
      } else {
        return [];
      }
    },
    authCompanyQuestionnaireResponses: state => {
      if (state.companyQuestionnaireResponses != "") {
        return JSON.parse(state.companyQuestionnaireResponses);
      } else {
        return [];
      }
    },
    authCompanyReviewers: state => {
      if (state.companyReviewers != "") {
        return JSON.parse(state.companyReviewers);
      } else {
        return [];
      }
    },
    authEmployeeAppraisals: state => {
      if (state.employeeAppraisals != "") {
        return JSON.parse(state.employeeAppraisals);
      } else {
        return [];
      }
    },
    authCompanyGoalsInAppraisals: state => {
      if (state.companyGoalsInAppraisals != "") {
        return JSON.parse(state.companyGoalsInAppraisals);
      } else {
        return [];
      }
    },
    authEmployeeAssignedGoals: state => {
      if (state.employeeAssignedGoals != "") {
        return JSON.parse(state.employeeAssignedGoals);
      } else {
        return [];
      }
    },
    authCompanyQuestionnaires: state => {
      if (state.companyQuestionnaires != "") {
        return JSON.parse(state.companyQuestionnaires);
      } else {
        return [];
      }
    },
    authEmployeesKRAs: state => {
      if (state.employeeKRAs != "") {
        return JSON.parse(state.employeeKRAs);
      } else {
        return [];
      }
    },
    authCompanyEmployeesInAppraisals: state => {
      if (state.companyEmployeesInAppraisals != "") {
        return JSON.parse(state.companyEmployeesInAppraisals);
      } else {
        return [];
      }
    },
    authCompanyEmployeesInGoals: state => {
      if (state.companyEmployeesInGoals != "") {
        return JSON.parse(state.companyEmployeesInGoals);
      } else {
        return [];
      }
    },
    authCompanyRequisitionComments: state => {
      if (state.companyRequisitionComments != "") {
        return JSON.parse(state.companyRequisitionComments);
      } else {
        return [];
      }
    },
    authEmployeeDockets: state => {
      if (state.employeeDockets != "") {
        return JSON.parse(state.employeeDockets);
      } else {
        return [];
      }
    },
    authEmployeeCommittees: state => {
      if (state.employeeCommittees != "") {
        return JSON.parse(state.employeeCommittees);
      } else {
        return [];
      }
    },
    authCompanyCommittees: state => {
      if (state.companyCommittees != "") {
        return JSON.parse(state.companyCommittees);
      } else {
        return [];
      }
    },
    authCompanyDepartmentEmployees: state => {
      if (state.companyDepartmentEmployees != "") {
        return JSON.parse(state.companyDepartmentEmployees);
      } else {
        return [];
      }
    },
    currentEmployeeDepartments: (state, getters) => {
      let filteredValues = [];
      if (getters.authCompanyDepartmentEmployees.length) {
        filteredValues = getters.authCompanyDepartmentEmployees.filter(item => {
          return item.employee_id == getters.validEmployeeId;
        });
      }
      return filteredValues;
    },
    currentEmployeeCommittees: (state, getters) => {
      let filteredValues = [];
      if (getters.authEmployeeCommittees.length) {
        filteredValues = getters.authEmployeeCommittees.filter(item => {
          return item.employee_id == getters.validEmployeeId;
        });
      }
      return filteredValues;
    },
    authCompanyDockets: state => {
      if (state.companyDockets != "") {
        return JSON.parse(state.companyDockets);
      } else {
        return [];
      }
    },
    authCompanyRequisitionTypes: state => {
      if (state.companyRequisitionTypes != "") {
        return JSON.parse(state.companyRequisitionTypes);
      } else {
        return [];
      }
    },
    authCompanyRequisitions: state => {
      if (state.companyRequisitions != "") {
        return JSON.parse(state.companyRequisitions);
      } else {
        return [];
      }
    },
    authEmployeeRequisitions: state => {
      if (state.employeeRequisitions != "") {
        return JSON.parse(state.employeeRequisitions);
      } else {
        return [];
      }
    },
    validEmployeeId: (state, getters) => {
      let employee = null,
        id = 0;
      if (getters.isAuth && getters.authCompanyEmployees.length) {
        employee = getters.authCompanyEmployees.filter(
          e => e.user_id == getters.authUserData.id
        );
        if (employee.length) {
          employee = employee[0];
          return employee.id;
        }
      }
      return id;
    },
    authCompanyLogs: state => {
      if (state.companyLogs != "") {
        return JSON.parse(state.companyLogs);
      } else {
        return [];
      }
    },
    authEmployeeTaskSheets: state => {
      if (state.employeeTaskSheets != "") {
        return JSON.parse(state.employeeTaskSheets);
      } else {
        return [];
      }
    },
    authCompanyTaskSheets: state => {
      return "" != state.companyTaskSheets
        ? JSON.parse(state.companyTaskSheets)
        : [];
    },
    authCompanyStatutoryDeductions: state => {
      return "" != state.companyStatutoryDeductions
        ? JSON.parse(state.companyStatutoryDeductions)
        : [];
    },
    authStatutoryDeductions: state => {
      return "" != state.statutoryDeductions
        ? JSON.parse(state.statutoryDeductions)
        : [];
    },
    authCompanyEmoluments: state => {
      return "" != state.companyEmoluments
        ? JSON.parse(state.companyEmoluments)
        : [];
    },
    authCompanyGradeLevels: state => {
      return "" != state.companyGradeLevels
        ? JSON.parse(state.companyGradeLevels)
        : [];
    },
    authCompanyJobDocuments: state => {
      return "" != state.companyJobDocuments
        ? JSON.parse(state.companyJobDocuments)
        : [];
    },
    authCompanyPayGradeHistories: state => {
      return "" != state.companyPayGradeHistories
        ? JSON.parse(state.companyPayGradeHistories)
        : [];
    },
    authCompanyPayGrades: state => {
      return "" != state.companyPayGrades
        ? JSON.parse(state.companyPayGrades)
        : [];
    },
    authCompanyGeoLocations: state => {
      return "" != state.companyGeoLocations
        ? JSON.parse(state.companyGeoLocations)
        : [];
    },
    authLiquidationRequests: state => {
      return "" != state.liquidationRequests
        ? JSON.parse(state.liquidationRequests)
        : [];
    },
    authCompanyLoans: state => {
      return "" != state.companyLoans ? JSON.parse(state.companyLoans) : [];
    },
    authEmployeeLoans: state => {
      return "" != state.employeeLoans ? JSON.parse(state.employeeLoans) : [];
    },
    authEmployeesAssignedToProjects: state => {
      return "" != state.employeesAssignedToProjects
        ? JSON.parse(state.employeesAssignedToProjects)
        : [];
    },
    authPayrollConfig: state => {
      return "" != state.payrollConfig ? JSON.parse(state.payrollConfig) : [];
    },
    authEmployeePayslipDetails: state => {
      return "" != state.employeePayslipDetails
        ? JSON.parse(state.employeePayslipDetails)
        : {};
    },
    authFlexibleAllowances: state => {
      return "" != state.flexibleAllowances
        ? JSON.parse(state.flexibleAllowances)
        : [];
    },
    authFlexibleDeductions: state => {
      return "" != state.flexibleDeductions
        ? JSON.parse(state.flexibleDeductions)
        : [];
    },
    authCompanyPayRun: state => {
      return "" != state.companyPayRun ? JSON.parse(state.companyPayRun) : [];
    },
    authFixedAllowances: state => {
      return "" != state.fixedAllowances
        ? JSON.parse(state.fixedAllowances)
        : [];
    },
    authFixedDeductions: state => {
      return "" != state.fixedDeductions
        ? JSON.parse(state.fixedDeductions)
        : [];
    },
    authEmployeeTaxes: state => {
      return "" != state.employeeTaxes ? JSON.parse(state.employeeTaxes) : [];
    },
    authEmployeePayCalendars: state => {
      return "" != state.employeePayCalendars
        ? JSON.parse(state.employeePayCalendars)
        : [];
    },
    authPayCalendars: state => {
      return "" != state.payCalendars ? JSON.parse(state.payCalendars) : [];
    },
    authAssignedPermissions: state => {
      return "" != state.assignedPermissions
        ? JSON.parse(state.assignedPermissions)
        : [];
    },
    authCompanyPermissions: state => {
      return "" != state.companyPermissions
        ? JSON.parse(state.companyPermissions)
        : [];
    },
    authCompanyHolidays: state => {
      return "" != state.companyHolidays
        ? JSON.parse(state.companyHolidays)
        : [];
    },
    authCompanyRoles: state => {
      return "" != state.companyRoles ? JSON.parse(state.companyRoles) : [];
    },
    authCompanyTasks: state => {
      return "" != state.companyTasks ? JSON.parse(state.companyTasks) : [];
    },
    adminEmployeeData: (state, getters) => {
      if (getters.authCompanyEmployees.length >= 1) {
        let allEmployees = getters.authCompanyEmployees;
        return allEmployees.filter(p => {
          return p.user_id == getters.authUserData.id;
        })[0];
      }
      return null;
    },
    authCompanyProjects: state => {
      return "" != state.companyProjects
        ? JSON.parse(state.companyProjects)
        : [];
    },
    allEmployeeShifts: state => {
      return "" != state.employeeShifts ? JSON.parse(state.employeeShifts) : [];
    },
    authShifts: state => {
      return "" != state.shifts ? JSON.parse(state.shifts) : [];
    },
    authDesignations: state => {
      return "" != state.designations ? JSON.parse(state.designations) : [];
    },
    authCheckinAllTime: state => {
      return "" != state.checkinAllTime ? JSON.parse(state.checkinAllTime) : [];
    },
    trial_version: (state, getters) => {
      if (getters.authCompanyAllBilling.paymenthistory.length) {
        if (
          getters.authCompanyAllBilling.paymenthistory[
            getters.authCompanyAllBilling.paymenthistory.length - 1
          ].subscription_type == 1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    auth_professionalbodies: state => {
      return "" != state.professionalbodies
        ? JSON.parse(state.professionalbodies)
        : [];
    },
    auth_works: state => {
      return "" != state.works ? JSON.parse(state.works) : [];
    },
    auth_referees: state => {
      return "" != state.referees ? JSON.parse(state.referees) : [];
    },
    auth_institutions: state => {
      return "" != state.institutions ? JSON.parse(state.institutions) : [];
    },
    auth_applicants: state => {
      return "" != state.applicants ? JSON.parse(state.applicants) : [];
    },
    auth_states: state => {
      return "" != state.states ? JSON.parse(state.states) : [];
    },
    auth_countries: state => {
      return "" != state.countries ? JSON.parse(state.countries) : [];
    },
    auth_company_travels: state => {
      return "" != state.company_travels
        ? JSON.parse(state.company_travels)
        : [];
    },
    auth_employee_travels: state => {
      return "" != state.employee_travels
        ? JSON.parse(state.employee_travels)
        : [];
    },
    auth_created_jobs: state => {
      return "" != state.created_jobs ? JSON.parse(state.created_jobs) : [];
    },
    auth_company_all_medicals: state => {
      return "" != state.company_all_medicals
        ? JSON.parse(state.company_all_medicals)
        : [];
    },
    true_employees: (state, getters) => {
      return "" != getters.authCompanyEmployees
        ? getters.authCompanyEmployees.filter(person => {
          return 1 != person.user_type;
        })
        : [];
    },
    number_of_employees: state => {
      return "" != state.companyData
        ? parseInt(JSON.parse(state.companyData).number_of_employees) - 1
        : 0;
    },
    auth_company_files: state => {
      if (state.company_files != "") {
        return JSON.parse(state.company_files);
      } else {
        return [];
      }
    },
    auth_file_types: state => {
      if (state.file_types != "") {
        return JSON.parse(state.file_types);
      } else {
        return [];
      }
    },
    months_left: state => {
      if (state.remaining_months != "") {
        return JSON.parse(state.remaining_months);
      } else {
        return "";
      }
    },
    two_fa_enabled: state => {
      if (state.two_fa_available != "") {
        return JSON.parse(state.two_fa_available);
      } else {
        return false;
      }
    },
    two_fa_confirmed: state => {
      if (state.two_fa_confirmed != "") {
        return JSON.parse(state.two_fa_confirmed);
      } else {
        return false;
      }
    },
    authAllAvailableServices: state => {
      if (state.allAvailableServices != "") {
        return JSON.parse(state.allAvailableServices);
      } else {
        return [];
      }
    },
    employee_id: (state, getter) => {
      if (getter.userType != 1) {
        return getter.authCompanyEmployees.filter(person => {
          return person.id == getter.authEmployeeDataForCompany.id;
        })[0].id;
      } else {
        return getter.authCompanyEmployees.filter(person => {
          return person.user_id == getter.authUserData.id;
        })[0].id;
      }
    },
    authCompanyAllTimesheets: state => {
      if (state.companyAllTimesheets != "") {
        return JSON.parse(state.companyAllTimesheets);
      } else {
        return [];
      }
    },
    authEmployeeTimesheets: state => {
      if (state.employeeTimesheets != "") {
        return JSON.parse(state.employeeTimesheets);
      } else {
        return [];
      }
    },
    authCompanyAttendanceConfig: state => {
      if (state.companyAttendanceConfig != "") {
        return JSON.parse(state.companyAttendanceConfig).attendantconfiguration;
      } else {
        return [];
      }
    },
    authAllRoles: state => {
      if (state.allRoles != "") {
        return JSON.parse(state.allRoles);
      } else {
        return [];
      }
    },
    authCompanyAllBilling: state => {
      if (state.companyAllBilling != "") {
        return JSON.parse(state.companyAllBilling);
      } else {
        return null;
      }
    },
    authSubscribedServices: (_state, getters) => {
      return getters.authCompanyAllBilling
        ? getters.authCompanyAllBilling.companyservices
        : []
    },
    authCompanyAllEvents: state => {
      if (state.companyAllEvents != "") {
        return JSON.parse(state.companyAllEvents);
      } else {
        return [];
      }
    },
    authCompanyAllEmployeeAnnouncements: state => {
      if (state.companyAllEmployeeAnnouncements != "") {
        return JSON.parse(state.companyAllEmployeeAnnouncements);
      } else {
        return [];
      }
    },
    authCompanyAllAnnouncements: state => {
      if (state.companyAllAnnouncements != "") {
        return JSON.parse(state.companyAllAnnouncements);
      } else {
        return [];
      }
    },
    authCompanyAllVendors: state => {
      if (state.companyAllVendors != "") {
        return JSON.parse(state.companyAllVendors);
      } else {
        return [];
      }
    },
    authCompanyAllCheckin: state => {
      if (state.companyAllCheckin != "") {
        return JSON.parse(state.companyAllCheckin);
      } else {
        return [];
      }
    },
    authCompanyApprovedLeave: state => {
      if (state.companyApprovedLeave != "") {
        return JSON.parse(state.companyApprovedLeave);
      } else {
        return [];
      }
    },
    authCompanyAllLeave: state => {
      if (state.companyAllLeave != "") {
        return JSON.parse(state.companyAllLeave);
      } else {
        return [];
      }
    },
    authCompanyAllLeaveType: state => {
      if (state.companyAllLeaveType != "") {
        return JSON.parse(state.companyAllLeaveType);
      } else {
        return [];
      }
    },
    authCompanyAllAssetType: state => {
      if (state.companyAllAssetType != "") {
        return JSON.parse(state.companyAllAssetType);
      } else {
        return [];
      }
    },
    authEmployeeDocuments: state => {
      if (state.employeeDocuments != "") {
        return JSON.parse(state.employeeDocuments);
      } else {
        return [];
      }
    },
    authCompanyAssets: state => {
      if (state.companyAssets != "") {
        return JSON.parse(state.companyAssets);
      } else {
        return [];
      }
    },
    checkInMs: state => {
      if (state.msTime != "" && !!state.msTime) {
        return parseInt(state.msTime);
      } else {
        return "";
      }
    },
    checkedIn: state => {
      if (state.checkInStatus == "") {
        return null;
      } else if (state.checkInStatus == "1") {
        return true;
      } else {
        return false;
      }
    },
    userType: (state, getters) => parseInt(getters.authUserData.user_type),
    isAuth: state => !!state.userToken,
    authStatus: state => state.status,
    authFetchEmployeeStatus: state => state.fetchEmployeeStatus,
    // authUserData: state => JSON.parse(state.userData)
    authUserData: state => {
      if (state.userData != "") {
        return JSON.parse(state.userData);
      } else {
        return "";
      }
    },
    authCompanyData: state => {
      if (state.companyData != "") {
        return JSON.parse(state.companyData);
      } else {
        return "";
      }
    },
    authEmployeeDataForCompany: state => {
      if (state.employeeDataForCompany != "") {
        return JSON.parse(state.employeeDataForCompany);
      } else {
        return "";
      }
    },
    authCompanyServices: state => {
      if (state.companyServices != "") {
        return JSON.parse(state.companyServices);
      } else {
        return [];
      }
    },
    authCompanyEmployees: state => {
      if (state.companyEmployees != "") {
        return JSON.parse(state.companyEmployees);
      } else {
        return [];
      }
    },
    authCompanyAppraisals: state => {
      if (state.companyAppraisals != "") {
        return JSON.parse(state.companyAppraisals);
      } else {
        return [];
      }
    },
    authCompanyDepartments: state => {
      if (state.companyDepartments != "") {
        return JSON.parse(state.companyDepartments);
      } else {
        return [];
      }
    },
    authCompanyKRAs: state => {
      if (state.companyKRAs != "") {
        return JSON.parse(state.companyKRAs);
      } else {
        return [];
      }
    },
    authCompanyGoals: state => {
      if (state.companyGoals != "") {
        return JSON.parse(state.companyGoals);
      } else {
        return [];
      }
    },
    authCompanyJobOffers: state => {
      if (state.companyJobCategories != "") {
        return JSON.parse(state.companyJobCategories);
      } else {
        return [];
      }
    },
    authCompanyCandidates: state => {
      if (state.companyCandidates != "") {
        return JSON.parse(state.companyCandidates);
      } else {
        return [];
      }
    },
    authSubscriptionExpired: state => {
      if (state.subscription_expired != "") {
        return JSON.parse(state.subscription_expired);
      } else {
        return false;
      }
    },
    authCompanyHospitals: state => {
      if (state.companyHospitals != "") {
        return JSON.parse(state.companyHospitals);
      } else {
        return false;
      }
    },
    humanResources(state, getters) {
      // return getters.authCompanyServices.filter(function (service) {
      //     return service.service_id >= 1 && service.service_id <= 8
      // }) || ''
      if (!getters.authCompanyServices) {
        return [];
      } else {
        return getters.authCompanyServices.filter(function (service) {
          return service.service_id; // >= 1 && service.service_id <= 30;
        });
      }
    },
    accounts(state, getters) {
      if (!getters.authCompanyServices) {
        return [];
      } else {
        return getters.authCompanyServices.filter(function (service) {
          return service === null; //service.service_id >= 15 && service.service_id <= 22;
        });
      }
    },
    bankNames: (state, getters) => {
      const names = getters.allBanks.map(bank => bank.bank_name);
      return names;
    }
  },
  mutations: {
    AUTH_FETCHED_COMPANY_DATA: (state, companyData) => {
      state.companyData = companyData;
    },
    AUTH_FETCHED_REQUISITION_ITEMS: (state, requisitionItems) => {
      state.requisitionItems = requisitionItems;
    },
    AUTH_FETCHED_COMPANY_OUTSOURCING_EMPLOYEES_BY_EMPLOYEE: (state, companyOutsourcingEmployeesByEmployee) => {
      state.companyOutsourcingEmployeesByEmployee = companyOutsourcingEmployeesByEmployee;
    },
    AUTH_FETCHED_COMPANY_OUTSOURCING_EMPLOYEES_BY_COMPANY: (state, companyOutsourcingEmployeesByCompany) => {
      state.companyOutsourcingEmployeesByCompany = companyOutsourcingEmployeesByCompany;
    },
    AUTH_FETCHED_COMPANY_OUTSOURCING_COMPANIES: (state, companyOutsourcingCompanies) => {
      state.companyOutsourcingCompanies = companyOutsourcingCompanies;
    },
    AUTH_FETCHED_EMPLOYEE_TRAININGS: (state, employeeTrainings) => {
      state.employeeTrainings = employeeTrainings;
    },
    AUTH_FETCHED_COMPANY_TRAININGS: (state, companyTrainings) => {
      state.companyTrainings = companyTrainings;
    },
    AUTH_FETCHED_COMPANY_TRAINING_PROVIDERS: (state, companyTrainingProviders) => {
      state.companyTrainingProviders = companyTrainingProviders;
    },
    AUTH_FETCHED_WORKFLOW_SERVICES: (state, workflowServices) => {
      state.workflowServices = workflowServices;
    },
    AUTH_FETCHED_COMPANY_APPROVAL_LEVELS: (state, companyApprovalLevels) => {
      state.companyApprovalLevels = companyApprovalLevels;
    },
    AUTH_FETCHED_COMPANY_WORKFLOWS: (state, companyWorkflows) => {
      state.companyWorkflows = companyWorkflows;
    },
    AUTH_FETCHED_COMPANY_COURSE_CATEGORIES: (state, companyCourseCategories) => {
      state.companyCourseCategories = companyCourseCategories;
    },
    AUTH_FETCHED_COMPANY_EXIT_CHECKLISTS: (state, companyExitChecklists) => {
      state.companyExitChecklists = companyExitChecklists;
    },
    AUTH_FETCHED_EMPLOYEE_EXIT_REQUESTS: (state, employeeExitRequests) => {
      state.employeeExitRequests = employeeExitRequests;
    },
    AUTH_FETCHED_COMPANY_EXIT_REQUESTS: (state, companyExitRequests) => {
      state.companyExitRequests = companyExitRequests;
    },
    AUTH_FETCHED_COMPANY_ATTENDANCE_DEDUCTION_SETTINGS: (state, companyAttendanceDeductionSettings) => {
      state.companyAttendanceDeductionSettings = companyAttendanceDeductionSettings;
    },
    AUTH_FETCHED_COMPANY_TRANSACTIONS: (state, companyTransactions) => {
      state.companyTransactions = companyTransactions;
    },
    AUTH_FETCHED_BLOG_POSTS: (state, allBlogPosts) => {
      state.allBlogPosts = allBlogPosts;
    },
    AUTH_FETCHED_COMPANY_SUBACCOUNT: (state, companySubaccount) => {
      state.companySubaccount = companySubaccount;
    },
    FETCHED_BANKS: (state, banks) => {
      state.banks = banks;
    },
    AUTH_FETCHED_COMPANY_BRANCHES: (state, companyBranches) => {
      state.companyBranches = companyBranches;
    },
    AUTH_FETCHED_COMPANY_SKILL_SETS: (state, companySkillSets) => {
      state.companySkillSets = companySkillSets;
    },
    AUTH_FETCHED_EMPLOYEE_SKILL_SETS: (state, employeeSkillSets) => {
      state.employeeSkillSets = employeeSkillSets;
    },
    AUTH_FETCHED_EMPLOYEE_LOAN_REPAYMENTS: (state, employeeLoanRepayments) => {
      state.employeeLoanRepayments = employeeLoanRepayments;
    },
    AUTH_FETCHED_COMPANY_LOAN_REPAYMENTS: (state, companyLoanRepayments) => {
      state.companyLoanRepayments = companyLoanRepayments;
    },
    AUTH_FETCHED_COMPANY_APPRAISAL_FEEDBACKS: (
      state,
      companyAppraisalFeedbacks
    ) => {
      state.companyAppraisalFeedbacks = companyAppraisalFeedbacks;
    },
    AUTH_FETCHED_COMPANY_FEEDBACKS: (state, companyFeedbacks) => {
      state.companyFeedbacks = companyFeedbacks;
    },
    AUTH_FETCHED_EMPLOYEE_REPORTERS: (state, employeeReporters) => {
      state.employeeReporters = employeeReporters;
    },
    AUTH_FETCHED_EMPLOYEE_GOAL_REVIEWS: (state, employeeGoalReviews) => {
      state.employeeGoalReviews = employeeGoalReviews;
    },
    AUTH_FETCHED_EMPLOYEE_APPRAISAL_REVIEWS: (
      state,
      employeeAppraisalReviews
    ) => {
      state.employeeAppraisalReviews = employeeAppraisalReviews;
    },
    AUTH_FETCHED_COMPANY_QUESTIONNAIRE_RESPONSES: (
      state,
      companyQuestionnaireResponses
    ) => {
      state.companyQuestionnaireResponses = companyQuestionnaireResponses;
    },
    AUTH_FETCHED_COMPANY_REVIEWERS: (state, companyReviewers) => {
      state.companyReviewers = companyReviewers;
    },
    AUTH_FETCHED_EMPLOYEE_APPRAISALS: (state, employeeAppraisals) => {
      state.employeeAppraisals = employeeAppraisals;
    },
    AUTH_FETCHED_COMPANY_GOALS_IN_APPRAISALS: (
      state,
      companyGoalsInAppraisals
    ) => {
      state.companyGoalsInAppraisals = companyGoalsInAppraisals;
    },
    AUTH_FETCHED_EMPLOYEE_ASSIGNED_GOALS: (state, employeeAssignedGoals) => {
      state.employeeAssignedGoals = employeeAssignedGoals;
    },
    AUTH_FETCHED_COMPANY_QUESTIONNAIRES: (state, companyQuestionnaires) => {
      state.companyQuestionnaires = companyQuestionnaires;
    },
    AUTH_FETCHED_COMPANY_EMPLOYEES_IN_APPRAISALS: (
      state,
      companyEmployeesInAppraisals
    ) => {
      state.companyEmployeesInAppraisals = companyEmployeesInAppraisals;
    },
    AUTH_FETCHED_EMPLOYEE_KRAS: (state, employeeKRAs) => {
      state.employeeKRAs = employeeKRAs;
    },
    AUTH_FETCHED_COMPANY_EMPLOYEES_IN_GOALS: (
      state,
      companyEmployeesInGoals
    ) => {
      state.companyEmployeesInGoals = companyEmployeesInGoals;
    },
    AUTH_FETCHED_COMPANY_REQUISITION_COMMENTS: (
      state,
      companyRequisitionComments
    ) => {
      state.companyRequisitionComments = companyRequisitionComments;
    },
    AUTH_FETCHED_EMPLOYEE_DOCKETS: (state, employeeDockets) => {
      state.employeeDockets = employeeDockets;
    },
    AUTH_FETCHED_EMPLOYEE_COMMITTEES: (state, employeeCommittees) => {
      state.employeeCommittees = employeeCommittees;
    },
    AUTH_FETCHED_COMPANY_COMMITTEES: (state, companyCommittees) => {
      state.companyCommittees = companyCommittees;
    },
    AUTH_FETCHED_COMPANY_DEPARTMENT_EMPLOYEES: (
      state,
      companyDepartmentEmployees
    ) => {
      state.companyDepartmentEmployees = companyDepartmentEmployees;
    },
    AUTH_FETCHED_COMPANY_DOCKETS: (state, companyDockets) => {
      state.companyDockets = companyDockets;
    },
    AUTH_FETCHED_COMPANY_REQUISITION_TYPES: (
      state,
      companyRequisitionTypes
    ) => {
      state.companyRequisitionTypes = companyRequisitionTypes;
    },
    AUTH_FETCHED_COMPANY_REQUISITIONS: (state, companyRequisitions) => {
      state.companyRequisitions = companyRequisitions;
    },
    AUTH_FETCHED_EMPLOYEE_REQUISITIONS: (state, employeeRequisitions) => {
      state.employeeRequisitions = employeeRequisitions;
    },
    AUTH_FETCHED_COMPANY_LOGS: (state, companyLogs) => {
      state.companyLogs = companyLogs;
    },
    AUTH_FETCHED_EMPLOYEE_TASKSHEETS: (state, employeeTaskSheets) => {
      state.employeeTaskSheets = employeeTaskSheets;
    },
    AUTH_FETCHED_COMPANY_TASK_SHEETS: function (state, companyTaskSheets) {
      state.companyTaskSheets = companyTaskSheets;
    },
    AUTH_FETCHED_COMPANY_STATUTORY_DEDUCTIONS: function (
      state,
      companyStatutoryDeductions
    ) {
      state.companyStatutoryDeductions = companyStatutoryDeductions;
    },
    AUTH_FETCHED_STATUTORY_DEDUCTIONS: function (state, statutoryDeductions) {
      state.statutoryDeductions = statutoryDeductions;
    },
    AUTH_FETCHED_COMPANY_EMOLUMENTS: function (state, companyEmoluments) {
      state.companyEmoluments = companyEmoluments;
    },
    AUTH_FETCHED_COMPANY_GRADE_LEVELS: function (state, companyGradeLevels) {
      state.companyGradeLevels = companyGradeLevels;
    },
    AUTH_FETCHED_COMPANY_JOB_DOCUMENTS: function (state, companyJobDocuments) {
      state.companyJobDocuments = companyJobDocuments;
    },
    AUTH_FETCHED_COMPANY_PAY_GRADE_HISTORIES: function (
      state,
      companyPayGradeHistories
    ) {
      state.companyPayGradeHistories = companyPayGradeHistories;
    },
    AUTH_FETCHED_COMPANY_PAY_GRADES: function (state, companyPayGrades) {
      state.companyPayGrades = companyPayGrades;
    },
    AUTH_FETCHED_COMPANY_GEO_LOCATIONS: function (state, companyGeoLocations) {
      state.companyGeoLocations = companyGeoLocations;
    },
    AUTH_FETCHED_LIQUIDATION_REQUESTS: function (state, liquidationRequests) {
      state.liquidationRequests = liquidationRequests;
    },
    AUTH_FETCHED_COMPANY_LOANS: function (state, companyLoans) {
      state.companyLoans = companyLoans;
    },
    AUTH_FETCHED_EMPLOYEE_LOANS: function (state, employeeLoans) {
      state.employeeLoans = employeeLoans;
    },
    AUTH_FETCHED_EMPLOYEES_ASSIGNED_TO_PROJECTS: function (
      state,
      employeesAssignedToProjects
    ) {
      state.employeesAssignedToProjects = employeesAssignedToProjects;
    },
    AUTH_FETCHED_PAYROLL_CONFIG: function (state, payrollConfig) {
      state.payrollConfig = payrollConfig;
    },
    AUTH_FETCHED_EMPLOYEE_PAYSLIPS: function (state, employeePayslipDetails) {
      state.employeePayslipDetails = employeePayslipDetails;
    },
    AUTH_FETCHED_FLEXIBLE_ALLOWANCES: function (state, flexibleAllowances) {
      state.flexibleAllowances = flexibleAllowances;
    },
    AUTH_FETCHED_FLEXIBLE_DEDUCTIONS: function (state, flexibleDeductions) {
      state.flexibleDeductions = flexibleDeductions;
    },
    AUTH_FETCHED_COMPANY_PAYRUN: function (state, companyPayRun) {
      state.companyPayRun = companyPayRun;
    },
    AUTH_FETCHED_FIXED_ALLOWANCES: function (state, fixedAllowances) {
      state.fixedAllowances = fixedAllowances;
    },
    AUTH_FETCHED_FIXED_DEDUCTIONS: function (state, fixedDeductions) {
      state.fixedDeductions = fixedDeductions;
    },
    AUTH_FETCHED_EMPLOYEE_TAXES: function (state, employeeTaxes) {
      state.employeeTaxes = employeeTaxes;
    },
    AUTH_FETCHED_EMPLOYEE_PAY_CALENDAR: function (state, employeePayCalendars) {
      state.employeePayCalendars = employeePayCalendars;
    },
    AUTH_FETCHED_PAY_CALENDAR: function (state, payCalendars) {
      state.payCalendars = payCalendars;
    },
    AUTH_FETCHED_ASSIGNED_PERMISSIONS: function (state, assignedPermissions) {
      state.assignedPermissions = assignedPermissions;
    },
    AUTH_FETCHED_ALL_PERMISSIONS: function (state, companyPermissions) {
      state.companyPermissions = companyPermissions;
    },
    AUTH_FETCHED_ALL_HOLIDAYS: function (state, companyHolidays) {
      state.companyHolidays = companyHolidays;
    },
    AUTH_FETCHED_COMPANY_ROLES: function (state, companyRoles) {
      state.companyRoles = companyRoles;
    },
    AUTH_FETCHED_ALL_TASKS: function (state, companyTasks) {
      state.companyTasks = companyTasks;
    },
    AUTH_FETCHED_ALL_PROJECTS: function (state, companyProjects) {
      state.companyProjects = companyProjects;
    },
    AUTH_FETCHED_ALL_EMPLOYEE_SHIFTS: function (state, employeeShifts) {
      state.employeeShifts = employeeShifts;
    },
    AUTH_FETCHED_ALL_SHIFTS: function (state, shifts) {
      state.shifts = shifts;
    },
    AUTH_FETCHED_ALL_DESIGNATIONS: function (state, designations) {
      state.designations = designations;
    },
    AUTH_FETCHED_CHECKIN_ALL_TIME: function (state, checkinAllTime) {
      state.checkinAllTime = checkinAllTime;
    },
    AUTH_FETCHED_PROFESSIONAL_BODIES: function (state, professionalbodies) {
      state.professionalbodies = professionalbodies;
    },
    AUTH_FETCHED_WORKS: function (state, works) {
      state.works = works;
    },
    AUTH_FETCHED_INSTITUTIONS: function (state, institutions) {
      state.institutions = institutions;
    },
    AUTH_FETCHED_REFEREES: function (state, referees) {
      state.referees = referees;
    },
    AUTH_FETCHED_APPLICANTS: function (state, applicants) {
      state.applicants = applicants;
    },
    AUTH_FETCHED_STATES: function (state, states) {
      state.states = states;
    },
    AUTH_FETCHED_COUNTRIES: function (state, countries) {
      state.countries = countries;
    },
    AUTH_FETCHED_COMPANY_JOBS: function (state, company_travels) {
      state.company_travels = company_travels;
    },
    AUTH_FETCHED_EMPLOYEE_JOBS: function (state, employee_travels) {
      state.employee_travels = employee_travels;
    },
    AUTH_FETCHED_ALL_JOBS: function (state, created_jobs) {
      state.created_jobs = created_jobs;
    },
    AUTH_FETCHED_ALL_MEDICALS: function (state, company_all_medicals) {
      state.company_all_medicals = company_all_medicals;
    },
    AUTH_FETCHED_ALL_COMPANY_FILES: (state, company_files) => {
      state.company_files = company_files;
    },
    AUTH_FETCHED_ALL_FILE_TYPES: (state, file_types) => {
      state.file_types = file_types;
    },
    AUTH_FETCHED_ALL_AVAILABLE_SERVICES: (state, allAvailableServices) => {
      state.allAvailableServices = allAvailableServices;
    },
    AUTH_FETCHED_ALL_TIMESHEETS: (state, companyAllTimesheets) => {
      state.companyAllTimesheets = companyAllTimesheets;
    },
    AUTH_FETCHED_EMPLOYEE_TIMESHEETS: (state, employeeTimesheets) => {
      state.employeeTimesheets = employeeTimesheets;
    },
    AUTH_FETCHED_ATTENDANCE_CONFIG: (state, config) => {
      state.companyAttendanceConfig = config;
    },
    AUTH_FETCHED_ALL_ROLES: (state, roles) => {
      state.allRoles = roles;
    },
    AUTH_FETCHED_ALL_BILLING: (state, billing) => {
      state.companyAllBilling = billing;
    },
    AUTH_FETCHED_ALL_EVENTS: (state, events) => {
      state.companyAllEvents = events;
    },
    AUTH_FETCHED_ALL_EMPLOYEE_ANNOUNCEMENTS: (state, announcements) => {
      state.companyAllEmployeeAnnouncements = announcements;
    },
    AUTH_FETCHED_ALL_ANNOUNCEMENTS: (state, announcements) => {
      state.companyAllAnnouncements = announcements;
    },
    AUTH_FETCHED_ALL_VENDORS: (state, vendors) => {
      state.companyAllVendors = vendors;
    },
    AUTH_FETCHED_ALL_CHECK_IN: (state, checkin) => {
      state.companyAllCheckin = checkin;
    },
    AUTH_REFRESH: (state, dataAndServices) => {
      state.userData = dataAndServices.userData;
      state.companyData = dataAndServices.companyData;
      state.companyServices = dataAndServices.companyServices;
      state.subscription_expired = dataAndServices.isExpired;
    },
    AUTH_LOADING: state => (state.status = "loading"),
    AUTH_SUCCESS: (state, tokenUserCompany) => {
      state.status = "success";
      state.userToken = tokenUserCompany[0];
      state.userData = tokenUserCompany[1];
      state.companyData = tokenUserCompany[2];
      state.remaining_months = tokenUserCompany[3];
      state.two_fa_available = tokenUserCompany[4];
      state.loggedInTime = new Date().getTime();
      localStorage.setItem("loggedInTime", state.loggedInTime);
    },
    AUTH_SUCCESS2: (state, tokenUserEmployee) => {
      state.status = "success";
      state.userToken = tokenUserEmployee[0];
      state.userData = tokenUserEmployee[1];
      state.employeeDataForCompany = tokenUserEmployee[2];
      state.companyData = tokenUserEmployee[3];
      state.two_fa_available = tokenUserEmployee[4];
      state.loggedInTime = new Date().getTime();
      localStorage.setItem("loggedInTime", state.loggedInTime);
    },
    AUTH_ERROR: state => (state.status = "error"),
    AUTH_LOGOUT: state => {
      state.status = "";
      state.userToken = "";
      state.userData = "";
      state.companyData = "";
      state.companyServices = "";
      state.companyEmployees = "";
      state.companyAppraisals = "";
      state.companyDepartments = "";
      state.companyKRAs = "";
      state.companyGoals = "";

      state.companyCandidates = "";
      state.subscription_expired = "";
      state.employeeDataForCompany = "";
      state.checkInStatus = "";
      state.msTime = "";
      state.companyAssets = "";
      state.companyApprovedLeave = "";
      state.companyAllLeave = "";
      state.companyAllLeaveType = "";
      state.companyAllAssetType = "";
      state.employeeDocuments = "";
      state.companyAllCheckin = "";
      state.companyAllVendors = "";
      state.companyAllAnnouncements = "";
      state.companyAllEmployeeAnnouncements = "";
      state.companyAllEvents = "";
      state.companyAllBilling = "";
      state.companyAttendanceConfig = "";
      state.employeeTimesheets = "";
      state.companyAllTimesheets = "";
      state.remaining_months = "";
      state.two_fa_available = "";
      state.two_fa_confirmed = "";
      state.file_types = "";
      state.company_files = "";
      state.company_all_medicals = "";
      state.created_jobs = "";
      state.employee_travels = "";
      state.company_travels = "";
      state.applicants = "";
      state.works = "";
      state.institutions = "";
      state.referees = "";
      state.professionalbodies = "";
      state.checkinAllTime = "";
      state.designations = "";
      state.shifts = "";
      state.employeeShifts = "";
      state.companyProjects = "";
      state.companyTasks = "";
      state.companyHolidays = "";
      state.companyRoles = "";
      state.companyPermissions = "";
      state.assignedPermissions = "";
      state.payCalendars = "";
      state.employeePayCalendars = "";
      state.fixedAllowances = "";
      state.fixedDeductions = "";
      state.employeeTaxes = "";
      state.companyPayRun = "";
      state.flexibleAllowances = "";
      state.flexibleDeductions = "";
      state.employeePayslipDetails = "";
      state.payrollConfig = "";
      state.employeesAssignedToProjects = "";
      state.companyHospitals = "";
      state.companyLoans = "";
      state.employeeLoans = "";
      state.liquidationRequests = "";
      state.companyGeoLocations = "";
      state.companyPayGrades = "";
      state.companyPayGradeHistories = "";
      state.loggedInTime = "";
      state.companyJobDocuments = "";
      state.companyGradeLevels = "";
      state.companyEmoluments = "";
      state.statutoryDeductions = "";
      state.companyStatutoryDeductions = "";
      state.companyTaskSheets = "";
      state.employeeTaskSheets = "";
      state.companyLogs = "";
      state.companyRequisitions = "";
      state.employeeRequisitions = "";
      state.companyRequisitionTypes = "";
      state.companyDockets = "";
      state.companyDepartmentEmployees = "";
      state.companyCommittees = "";
      state.employeeCommittees = "";
      state.employeeDockets = "";
      state.companyRequisitionComments = "";
      state.companyEmployeesInGoals = "";
      state.employeeKRAs = "";
      state.companyEmployeesInAppraisals = "";
      state.companyQuestionnaires = "";
      state.employeeAssignedGoals = "";
      state.companyGoalsInAppraisals = "";
      state.companyReviewers = "";
      state.companyQuestionnaireResponses = "";
      state.employeeAppraisalReviews = "";
      state.employeeGoalReviews = "";
      state.employeeReporters = "";
      state.companyFeedbacks = "";
      state.companyAppraisalFeedbacks = "";
      state.companyLoanRepayments = "";
      state.employeeLoanRepayments = "";
      state.employeeSkillSets = "";
      state.companySkillSets = "";
      state.companySubaccount = "";
      state.companyTransactions = "";
      state.companyAttendanceDeductionSettings = "";
      state.companyExitRequests = "";
      state.employeeExitRequests = "";
      state.companyExitChecklists = "";
      state.companyCourseCategories = "";
      state.companyWorkflows = "";
      state.companyApprovalLevels = "";
      state.workflowServices = "";
      state.requisitionItems = "";
    },
    AUTH_UNVERIFIED: state => (state.status = "unverified"),
    AUTH_REGISTERED: state => (state.status = "registered"),
    AUTH_FETCHED_EMPLOYEES: (state, data) => {
      state.companyEmployees = data.employees;
      state.fetchEmployeeStatus = data.status;
    },
    AUTH_FETCHED_APPRAISALS: (state, appraisals) => {
      state.companyAppraisals = appraisals;
    },
    AUTH_FETCHED_DEPARTMENTS: (state, departments) => {
      state.companyDepartments = departments;
    },
    AUTH_FETCHED_KRAS: (state, kras) => {
      state.companyKRAs = kras;
    },
    AUTH_FETCHED_GOALS: (state, goals) => {
      state.companyGoals = goals;
    },
    AUTH_FETCHED_JOB_CATEGORIES: (state, jobCategories) => {
      state.companyJobCategories = jobCategories;
    },
    AUTH_FETCHED_CANDIDATES: (state, candidates) => {
      state.companyCandidates = candidates;
    },
    AUTH_FETCHED_ASSETS: (state, assets) => {
      state.companyAssets = assets;
    },
    AUTH_FETCHED_APPROVED_LEAVE: (state, leave) => {
      state.companyApprovedLeave = leave;
    },
    AUTH_FETCHED_ALL_LEAVE: (state, leave) => {
      state.companyAllLeave = leave;
    },
    AUTH_FETCHED_ALL_LEAVE_TYPE: (state, leave) => {
      state.companyAllLeaveType = leave;
    },
    AUTH_FETCHED_ALL_ASSET_TYPE: (state, asset) => {
      state.companyAllAssetType = asset;
    },
    AUTH_FETCHED_EMPLOYEE_DOCUMENTS: (state, asset) => {
      state.employeeDocuments = asset;
    },
    GET_CHECK_IN_STATUS: (state, attendant) => {
      state.checkInStatus = attendant;
    },
    SUBSCRIPTION_RENEWED: state => {
      state.subscription_expired = false;
      state.remaining_months = "11";
    },
    SUBSCRIPTION_EXPIRED: (state, truth) => {
      state.subscription_expired = truth.isExpired;
      state.companyServices = truth.companyServices;
    },
    SET_CHECK_IN_TIME: (state, msTime) => {
      state.msTime = msTime;
    },
    TRACK_REGISTER_ERROR: (state, registerError) => {
      state.registerError = registerError;
    },
    AUTH_FETCHED_ALL_COMPANY_HOSPITALS: (state, companyHospitals) => {
      state.companyHospitals = companyHospitals;
    },
    SET_TWO_FACTOR_AUTH: (state, status) => {
      state.two_fa_available = status;
    },
    SET_TWO_FACTOR_AUTH_CONFIRMATION: (state, status) => {
      state.two_fa_confirmed = status;
    }
  },
  actions: {
    fetch_requisition_items: async (context, requisitionId) => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchrequisitionitembyrequisitionid/${requisitionId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "requisitionItems",
          JSON.stringify(RESPONSE.data.items)
        );
        context.commit(
          "AUTH_FETCHED_REQUISITION_ITEMS",
          JSON.stringify(RESPONSE.data.items)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_outsourcing_employees_by_employee: async context => {
      try {
        const employeeId = context.getters.validEmployeeId;
        const RESPONSE = await axios.get(`${BASE_URL}fetchoutsourcingemployeesbyemployee/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyOutsourcingEmployeesByEmployee",
          JSON.stringify(RESPONSE.data.employeelists)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_OUTSOURCING_EMPLOYEES_BY_EMPLOYEE",
          JSON.stringify(RESPONSE.data.employeelists)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_outsourcing_employees_by_company: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchoutsourcingemployeesbycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyOutsourcingEmployeesByCompany",
          JSON.stringify(RESPONSE.data.employeelists)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_OUTSOURCING_EMPLOYEES_BY_COMPANY",
          JSON.stringify(RESPONSE.data.employeelists)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_outsourcing_companies: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchoutsourcingcompanies/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyOutsourcingCompanies",
          JSON.stringify(RESPONSE.data.outsourcingcompanies)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_OUTSOURCING_COMPANIES",
          JSON.stringify(RESPONSE.data.outsourcingcompanies)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_trainings: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const employeeId = context.getters.validEmployeeId;
        const RESPONSE = await axios.get(`${BASE_URL}fetchemployeestraining/${companyId}/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "employeeTrainings",
          JSON.stringify(RESPONSE.data.employeetrainings)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_TRAININGS",
          JSON.stringify(RESPONSE.data.employeetrainings)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_trainings: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchtrainings/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyTrainings",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_TRAININGS",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_training_providers: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchtrainingproviders/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyTrainingProviders",
          JSON.stringify(RESPONSE.data.trainingproviders)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_TRAINING_PROVIDERS",
          JSON.stringify(RESPONSE.data.trainingproviders)
        );
      } catch (error) {
        //
      }
    },
    fetch_workflow_services: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchworkflowservices`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "workflowServices",
          JSON.stringify(RESPONSE.data.workflowservices)
        );
        context.commit(
          "AUTH_FETCHED_WORKFLOW_SERVICES",
          JSON.stringify(RESPONSE.data.workflowservices)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_approval_levels: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchapprovallevels/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyApprovalLevels",
          JSON.stringify(RESPONSE.data.approvallevels)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_APPROVAL_LEVELS",
          JSON.stringify(RESPONSE.data.approvallevels)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_workflows: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchworkflowsettings/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyWorkflows",
          JSON.stringify(RESPONSE.data.workflowsettings)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_WORKFLOWS",
          JSON.stringify(RESPONSE.data.workflowsettings)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_course_categories: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchcoursecategory/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyCourseCategories",
          JSON.stringify(RESPONSE.data.Coursecategories)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_COURSE_CATEGORIES",
          JSON.stringify(RESPONSE.data.Coursecategories)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_exit_checklists: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchexitchecklists/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyExitChecklists",
          JSON.stringify(RESPONSE.data.exitchecklists)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_EXIT_CHECKLISTS",
          JSON.stringify(RESPONSE.data.exitchecklists)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_exit_requests: async context => {
      try {
        const employeeId = context.getters.validEmployeeId || context.getters.authEmployeeDataForCompany.id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchexitrequestbyemployee/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "employeeExitRequests",
          JSON.stringify(RESPONSE.data.exitrequests)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_EXIT_REQUESTS",
          JSON.stringify(RESPONSE.data.exitrequests)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_exit_requests: async context => {
      try {
        const companyId = context.getters.authCompanyData.id || context.getters.authEmployeeDataForCompany.company_id;
        const RESPONSE = await axios.get(`${BASE_URL}fetchexitrequestbycompany/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          });
        localStorage.setItem(
          "companyExitRequests",
          JSON.stringify(RESPONSE.data.exitrequests)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_EXIT_REQUESTS",
          JSON.stringify(RESPONSE.data.exitrequests)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_attendance_deduction_settings: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}getattendancedeductionsettings`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAttendanceDeductionSettings",
          JSON.stringify(RESPONSE.data.settings)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_ATTENDANCE_DEDUCTION_SETTINGS",
          JSON.stringify(RESPONSE.data.settings)
        );
      } catch (error) {
        //
      }
    },
    set_two_factor_auth_confirmation: (context, status) => {
      localStorage.setItem("two_fa_confirmed", JSON.stringify(status));
      context.commit('SET_TWO_FACTOR_AUTH_CONFIRMATION', JSON.stringify(status));
    },
    set_two_factor_auth: (context, status) => {
      localStorage.setItem("two_fa_available", JSON.stringify(status));
      context.commit('SET_TWO_FACTOR_AUTH', JSON.stringify(status));
    },
    fetch_company_transactions: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchtransactions`, {
          reference: context.getters.authCompanySubaccount.account_reference
        });
        localStorage.setItem(
          "companyTransactions",
          JSON.stringify(RESPONSE.data.transactions.data.transactions)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_TRANSACTIONS",
          JSON.stringify(RESPONSE.data.transactions.data.transactions)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_blog_posts: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchposts`);
        localStorage.setItem(
          "allBlogPosts",
          JSON.stringify(RESPONSE.data.posts)
        );
        context.commit(
          "AUTH_FETCHED_BLOG_POSTS",
          JSON.stringify(RESPONSE.data.posts)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_subaccount: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchsubaccountbycompanyid`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companySubaccount",
          JSON.stringify(RESPONSE.data.subaccount)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_SUBACCOUNT",
          JSON.stringify(RESPONSE.data.subaccount)
        );
      } catch (error) {
        //
      }
    },
    fetch_banks: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchbanks`);
        localStorage.setItem("banks", JSON.stringify(RESPONSE.data.banks));
        context.commit("FETCHED_BANKS", JSON.stringify(RESPONSE.data.banks));
      } catch (error) {
        //
      }
    },
    fetch_company_branches: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchbranches`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyBranches",
          JSON.stringify(RESPONSE.data.branches)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_BRANCHES",
          JSON.stringify(RESPONSE.data.branches)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_skill_sets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchSkillsetByCom`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "companySkillSets",
          JSON.stringify(RESPONSE.data.Skillset)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_SKILL_SETS",
          JSON.stringify(RESPONSE.data.Skillset)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_skill_sets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchSkillset`, {
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeSkillSets",
          JSON.stringify(RESPONSE.data.Skillset)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_SKILL_SETS",
          JSON.stringify(RESPONSE.data.Skillset)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_loan_repayments: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeloanrepayment`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id,
            employee_id:
              context.getters.authEmployeeDataForCompany.id ||
              context.getters.validEmployeeId
          }
        );
        localStorage.setItem(
          "employeeLoanRepayments",
          JSON.stringify(RESPONSE.data.loanrepayments)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_LOAN_REPAYMENTS",
          JSON.stringify(RESPONSE.data.loanrepayments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_loan_repayments: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeloanrepaymentbycompany`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyLoanRepayments",
          JSON.stringify(RESPONSE.data.loanrepayments)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_LOAN_REPAYMENTS",
          JSON.stringify(RESPONSE.data.loanrepayments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_appraisal_feedbacks: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchappraisalfeedback`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAppraisalFeedbacks",
          JSON.stringify(RESPONSE.data.reviewers)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_APPRAISAL_FEEDBACKS",
          JSON.stringify(RESPONSE.data.reviewers)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_feedbacks: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeefeedbacks`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyFeedbacks",
          JSON.stringify(RESPONSE.data.myfeedback)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_FEEDBACKS",
          JSON.stringify(RESPONSE.data.myfeedback)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_reporters: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchmyreportingto`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeReporters",
          JSON.stringify(RESPONSE.data.employees)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_REPORTERS",
          JSON.stringify(RESPONSE.data.employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_goal_reviews: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeegoalreviews`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "employeeGoalReviews",
          JSON.stringify(RESPONSE.data.reviewers)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_GOAL_REVIEWS",
          JSON.stringify(RESPONSE.data.reviewers)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_appraisal_reviews: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeappraisalreviews`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "employeeAppraisalReviews",
          JSON.stringify(RESPONSE.data.reviewers)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_APPRAISAL_REVIEWS",
          JSON.stringify(RESPONSE.data.reviewers)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_questionnaire_responses: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchquestionnaireresponse`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyQuestionnaireResponses",
          JSON.stringify(RESPONSE.data.questionnaire)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_QUESTIONNAIRE_RESPONSES",
          JSON.stringify(RESPONSE.data.questionnaire)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_reviewers: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchreviewers`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyReviewers",
          JSON.stringify(RESPONSE.data.reviewers)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_REVIEWERS",
          JSON.stringify(RESPONSE.data.reviewers)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_appraisals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}employeeappraisalall`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeAppraisals",
          JSON.stringify(RESPONSE.data.employeeappraisal)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_APPRAISALS",
          JSON.stringify(RESPONSE.data.employeeappraisal)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_goals_in_appraisals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchgoalsbyappraisal`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyGoalsInAppraisals",
          JSON.stringify(RESPONSE.data.goals)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_GOALS_IN_APPRAISALS",
          JSON.stringify(RESPONSE.data.goals)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_assigned_goals: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeassignedgoals`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id,
            employee_id:
              context.getters.authEmployeeDataForCompany.id ||
              context.getters.validEmployeeId
          }
        );
        localStorage.setItem(
          "employeeAssignedGoals",
          JSON.stringify(RESPONSE.data.employeegoals)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_ASSIGNED_GOALS",
          JSON.stringify(RESPONSE.data.employeegoals)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_questionnaires: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchquestionnaire`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyQuestionnaires",
          JSON.stringify(RESPONSE.data.questionnaires)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_QUESTIONNAIRES",
          JSON.stringify(RESPONSE.data.questionnaires)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employees_in_appraisals: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeesinappraisal`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyEmployeesInAppraisals",
          JSON.stringify(RESPONSE.data.employees)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_EMPLOYEES_IN_APPRAISALS",
          JSON.stringify(RESPONSE.data.employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employee_kras: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeekra`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeKRAs",
          JSON.stringify(RESPONSE.data.employeekras)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_KRAS",
          JSON.stringify(RESPONSE.data.employeekras)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employees_in_goals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeesingoals`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyEmployeesInGoals",
          JSON.stringify(RESPONSE.data.employeegoals)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_EMPLOYEES_IN_GOALS",
          JSON.stringify(RESPONSE.data.employeegoals)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_requisition_comments: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchallreqcommentbycompany`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyRequisitionComments",
          JSON.stringify(RESPONSE.data.comments)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_REQUISITION_COMMENTS",
          JSON.stringify(RESPONSE.data.comments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employee_dockets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeedocket`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeDockets",
          JSON.stringify(RESPONSE.data.dockets)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_DOCKETS",
          JSON.stringify(RESPONSE.data.dockets)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employee_committees: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeecommittees`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "employeeCommittees",
          JSON.stringify(RESPONSE.data.employeecommittees)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_COMMITTEES",
          JSON.stringify(RESPONSE.data.employeecommittees)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_committees: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcommittee`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyCommittees",
          JSON.stringify(RESPONSE.data.committee)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_COMMITTEES",
          JSON.stringify(RESPONSE.data.committee)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_department_employees: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeedepartments`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyDepartmentEmployees",
          JSON.stringify(RESPONSE.data.employeedepartments)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_DEPARTMENT_EMPLOYEES",
          JSON.stringify(RESPONSE.data.employeedepartments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_dockets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchdocket`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.validEmployeeId
        });
        localStorage.setItem(
          "companyDockets",
          JSON.stringify(RESPONSE.data.dockets)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_DOCKETS",
          JSON.stringify(RESPONSE.data.dockets)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_requisition_types: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchrequistiontype`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyRequisitionTypes",
          JSON.stringify(RESPONSE.data.Requistions)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_REQUISITION_TYPES",
          JSON.stringify(RESPONSE.data.Requistions)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_requisitions: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchrequistion`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.validEmployeeId
        });
        localStorage.setItem(
          "companyRequisitions",
          JSON.stringify(RESPONSE.data.requisitions_with_approvals)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_REQUISITIONS",
          JSON.stringify(RESPONSE.data.requisitions_with_approvals)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_requisitions: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchRequistionByEmployeeId`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          request_by: context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeRequisitions",
          JSON.stringify(RESPONSE.data.requisitions_with_approvals)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_REQUISITIONS",
          JSON.stringify(RESPONSE.data.requisitions_with_approvals)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_logs: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchlogs`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem("companyLogs", JSON.stringify(RESPONSE.data.logs));
        context.commit(
          "AUTH_FETCHED_COMPANY_LOGS",
          JSON.stringify(RESPONSE.data.logs)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_tasksheets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeetasksheet`, {
          company_id: context.getters.authEmployeeDataForCompany.company_id,
          employee_id:
            context.getters.authEmployeeDataForCompany.id ||
            context.getters.validEmployeeId
        });
        localStorage.setItem(
          "employeeTaskSheets",
          JSON.stringify(RESPONSE.data.tasksheets)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_TASKSHEETS",
          JSON.stringify(RESPONSE.data.tasksheets)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_tasksheets: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchtasksheetbycompany`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyTaskSheets",
          JSON.stringify(RESPONSE.data.tasksheets)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_TASK_SHEETS",
          JSON.stringify(RESPONSE.data.tasksheets)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_statutory_deductions: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchcompanystatutorydeductions`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyStatutoryDeductions",
          JSON.stringify(RESPONSE.data.statutorydeductions)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_STATUTORY_DEDUCTIONS",
          JSON.stringify(RESPONSE.data.statutorydeductions)
        );
      } catch (error) {
        //
      }
    },
    fetch_statutory_deductions: async context => {
      try {
        const RESPONSE = await axios.get(
          `${BASE_URL}fetchallstatutorydeductions`
        );
        localStorage.setItem(
          "statutoryDeductions",
          JSON.stringify(RESPONSE.data.statutory_deductions)
        );
        context.commit(
          "AUTH_FETCHED_STATUTORY_DEDUCTIONS",
          JSON.stringify(RESPONSE.data.statutory_deductions)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_emoluments: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyemoluments`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyEmoluments",
          JSON.stringify(RESPONSE.data.emoluments)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_EMOLUMENTS",
          JSON.stringify(RESPONSE.data.emoluments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_grade_levels: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchgradelevel`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyGradeLevels",
          JSON.stringify(RESPONSE.data.gradelevel)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_GRADE_LEVELS",
          JSON.stringify(RESPONSE.data.gradelevel)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_job_documents: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchjobdocumentforpublicusers`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyJobDocuments",
          JSON.stringify(RESPONSE.data.jobdocuments)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_JOB_DOCUMENTS",
          JSON.stringify(RESPONSE.data.jobdocuments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_pay_grade_histories: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeepaygradehistory`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyPayGradeHistories",
          JSON.stringify(RESPONSE.data.paygradehistories)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_PAY_GRADE_HISTORIES",
          JSON.stringify(RESPONSE.data.paygradehistories)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_pay_grades: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchpaygrade`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyPayGrades",
          JSON.stringify(RESPONSE.data.paygrade)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_PAY_GRADES",
          JSON.stringify(RESPONSE.data.paygrade)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_geo_locations: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchcompanygeolocation`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyGeoLocations",
          JSON.stringify(RESPONSE.data.geolocations)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_GEO_LOCATIONS",
          JSON.stringify(RESPONSE.data.geolocations)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_liquidation_requests: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchliquidationrequest`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "liquidationRequests",
          JSON.stringify(RESPONSE.data.liquidation)
        );
        context.commit(
          "AUTH_FETCHED_LIQUIDATION_REQUESTS",
          JSON.stringify(RESPONSE.data.liquidation)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_loans: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchloansbycompany`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyLoans",
          JSON.stringify(RESPONSE.data.loans)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_LOANS",
          JSON.stringify(RESPONSE.data.loans)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employee_loans: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeeloans`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.validEmployeeId || context.getters.authEmployeeDataForCompany.id
        });
        localStorage.setItem(
          "employeeLoans",
          JSON.stringify(RESPONSE.data.loans)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_LOANS",
          JSON.stringify(RESPONSE.data.loans)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_hospitals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyhospitals`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyHospitals",
          JSON.stringify(RESPONSE.data.hospitals)
        );
        context.commit(
          "AUTH_FETCHED_ALL_COMPANY_HOSPITALS",
          JSON.stringify(RESPONSE.data.hospitals)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employees_assigned_to_projects: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchassignprojectcom`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "employeesAssignedToProjects",
          JSON.stringify(RESPONSE.data.assignproject)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEES_ASSIGNED_TO_PROJECTS",
          JSON.stringify(RESPONSE.data.assignproject)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_payroll_configuration: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchcompanypayrollconfiguration`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "payrollConfig",
          JSON.stringify(RESPONSE.data.payrollconfigrations)
        );
        context.commit(
          "AUTH_FETCHED_PAYROLL_CONFIG",
          JSON.stringify(RESPONSE.data.payrollconfigrations)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employee_payslips: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeepayslips`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.validEmployeeId || context.getters.authEmployeeDataForCompany.id
        });
        localStorage.setItem(
          "employeePayslipDetails",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_PAYSLIPS",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        console.log(error);
      }
    },
    fetch_all_flexible_allowances: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeallowances`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "flexibleAllowances",
          JSON.stringify(RESPONSE.data.employeeallowances)
        );
        context.commit(
          "AUTH_FETCHED_FLEXIBLE_ALLOWANCES",
          JSON.stringify(RESPONSE.data.employeeallowances)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_flexible_deductions: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeedeductions`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "flexibleDeductions",
          JSON.stringify(RESPONSE.data.employeededuction)
        );
        context.commit(
          "AUTH_FETCHED_FLEXIBLE_DEDUCTIONS",
          JSON.stringify(RESPONSE.data.employeededuction)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_payrun: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchpayrun`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyPayRun",
          JSON.stringify(RESPONSE.data.payrun)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_PAYRUN",
          JSON.stringify(RESPONSE.data.payrun)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_fixed_allowances: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeefixedallowances`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "fixedAllowances",
          JSON.stringify(RESPONSE.data.employeeallowances)
        );
        context.commit(
          "AUTH_FETCHED_FIXED_ALLOWANCES",
          JSON.stringify(RESPONSE.data.employeeallowances)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_fixed_deductions: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeefixeddeductions`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "fixedDeductions",
          JSON.stringify(RESPONSE.data.employeededuction)
        );
        context.commit(
          "AUTH_FETCHED_FIXED_DEDUCTIONS",
          JSON.stringify(RESPONSE.data.employeededuction)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employees_tax: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeetax`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "employeeTaxes",
          JSON.stringify(RESPONSE.data.employeetax)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_TAXES",
          JSON.stringify(RESPONSE.data.employeetax)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employees_pay_calendar: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeespaycalendar`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "employeePayCalendars",
          JSON.stringify(RESPONSE.data.employeespaycalendar)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_PAY_CALENDAR",
          JSON.stringify(RESPONSE.data.employeespaycalendar)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_pay_calendar: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchpaycalendar`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "payCalendars",
          JSON.stringify(RESPONSE.data.paycalendars)
        );
        context.commit(
          "AUTH_FETCHED_PAY_CALENDAR",
          JSON.stringify(RESPONSE.data.paycalendars)
        );
      } catch (error) {
        //
      }
    },
    fetch_assigned_permissions: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchcompanyassignedpermissions`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "assignedPermissions",
          JSON.stringify(RESPONSE.data.company_permissions)
        );
        context.commit(
          "AUTH_FETCHED_ASSIGNED_PERMISSIONS",
          JSON.stringify(RESPONSE.data.company_permissions)
        );
      } catch (error) {
        //
      }
    },
    refresh_checkin: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchallcompanycheckingalltime`,
          {
            company_id: context.getters.authEmployeeDataForCompany.company_id
          }
        );
        let allAttendants, userAttendants, latestCheckin;
        allAttendants = RESPONSE.data.user_attendance;
        userAttendants = allAttendants.filter(
          a => a.employee_id == context.getters.authEmployeeDataForCompany.id
        );
        latestCheckin = userAttendants[userAttendants.length - 1];

        if (
          latestCheckin &&
          new Date(latestCheckin.ms_time).toDateString() ==
          new Date().toDateString()
        ) {
          if (!latestCheckin.check_out) {
            localStorage.setItem(
              "msTime",
              new Date(latestCheckin.ms_time).getTime()
            );
            context.commit(
              "SET_CHECK_IN_TIME",
              new Date(latestCheckin.ms_time).getTime()
            );
          }
          localStorage.setItem("checkInStatus", latestCheckin.check_in_status);
          let status =
            latestCheckin.check_in_status == "null"
              ? null
              : latestCheckin.check_in_status;
          context.commit("GET_CHECK_IN_STATUS", status);
        } else {
          localStorage.setItem("checkInStatus", 0);
          context.commit("GET_CHECK_IN_STATUS", 0);
        }
      } catch (error) {
        //
      }
    },
    fetch_all_permissions: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchpermissions`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyPermissions",
          JSON.stringify(RESPONSE.data.permissions)
        );
        context.commit(
          "AUTH_FETCHED_ALL_PERMISSIONS",
          JSON.stringify(RESPONSE.data.permissions)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_holidays: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchallholiday`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyHolidays",
          JSON.stringify(RESPONSE.data.holidays)
        );
        context.commit(
          "AUTH_FETCHED_ALL_HOLIDAYS",
          JSON.stringify(RESPONSE.data.holidays)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_roles: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyrole`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyRoles",
          JSON.stringify(RESPONSE.data.roles)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_ROLES",
          JSON.stringify(RESPONSE.data.roles)
        );
      } catch (error) {
        //
      }
    },
    renew_subscription: context => {
      localStorage.setItem("subscription_expired", JSON.stringify(false));
      localStorage.setItem("remaining_months", JSON.stringify(11));
      context.commit("SUBSCRIPTION_RENEWED");
    },
    fetch_all_company_tasks: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchalltask`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyTasks",
          JSON.stringify(RESPONSE.data.tasks)
        );
        context.commit(
          "AUTH_FETCHED_ALL_TASKS",
          JSON.stringify(RESPONSE.data.tasks)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_projects: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchproject`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyProjects",
          JSON.stringify(RESPONSE.data.projects)
        );
        context.commit(
          "AUTH_FETCHED_ALL_PROJECTS",
          JSON.stringify(RESPONSE.data.projects)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employee_shifts: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeshiftbycompanyid`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "employeeShifts",
          JSON.stringify(RESPONSE.data.employee_shifts)
        );
        context.commit(
          "AUTH_FETCHED_ALL_EMPLOYEE_SHIFTS",
          JSON.stringify(RESPONSE.data.employee_shifts)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_shifts: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyshifts`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem("shifts", JSON.stringify(RESPONSE.data.shifts));
        context.commit(
          "AUTH_FETCHED_ALL_SHIFTS",
          JSON.stringify(RESPONSE.data.shifts)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_designations: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchdesignation`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "designations",
          JSON.stringify(RESPONSE.data.designations)
        );
        context.commit(
          "AUTH_FETCHED_ALL_DESIGNATIONS",
          JSON.stringify(RESPONSE.data.designations)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_check_in_all_time: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchallcompanycheckingalltime`,
          {
            company_id: context.getters.authCompanyData.id
          }
        );
        localStorage.setItem(
          "checkinAllTime",
          JSON.stringify(RESPONSE.data.user_attendance)
        );
        context.commit(
          "AUTH_FETCHED_CHECKIN_ALL_TIME",
          JSON.stringify(RESPONSE.data.user_attendance)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_applicants: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchapplicants`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem(
          "applicants",
          JSON.stringify(RESPONSE.data.applicants)
        );
        localStorage.setItem(
          "institutions",
          JSON.stringify(RESPONSE.data.institutions)
        );
        localStorage.setItem("works", JSON.stringify(RESPONSE.data.works));
        localStorage.setItem(
          "referees",
          JSON.stringify(RESPONSE.data.referees)
        );
        localStorage.setItem(
          "professionalbodies",
          JSON.stringify(RESPONSE.data.professionalbodies)
        );
        context.commit(
          "AUTH_FETCHED_APPLICANTS",
          JSON.stringify(RESPONSE.data.applicants)
        );
        context.commit(
          "AUTH_FETCHED_INSTITUTIONS",
          JSON.stringify(RESPONSE.data.institutions)
        );
        context.commit(
          "AUTH_FETCHED_WORKS",
          JSON.stringify(RESPONSE.data.works)
        );
        context.commit(
          "AUTH_FETCHED_REFEREES",
          JSON.stringify(RESPONSE.data.referees)
        );
        context.commit(
          "AUTH_FETCHED_PROFESSIONAL_BODIES",
          JSON.stringify(RESPONSE.data.professionalbodies)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_states: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchstates`);
        localStorage.setItem("states", JSON.stringify(RESPONSE.data.states));
        context.commit(
          "AUTH_FETCHED_STATES",
          JSON.stringify(RESPONSE.data.states)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_countries: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchcountries`);
        localStorage.setItem(
          "countries",
          JSON.stringify(RESPONSE.data.countries)
        );
        context.commit(
          "AUTH_FETCHED_COUNTRIES",
          JSON.stringify(RESPONSE.data.countries)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_travels: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchtravelsbycompany`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem(
          "company_travels",
          JSON.stringify(RESPONSE.data.travels)
        );
        context.commit(
          "AUTH_FETCHED_COMPANY_JOBS",
          JSON.stringify(RESPONSE.data.travels)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_travels: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchtravelsbycompany`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.authEmployeeDataForCompany.id
        });
        localStorage.setItem(
          "employee_travels",
          JSON.stringify(RESPONSE.data.travels)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_JOBS",
          JSON.stringify(RESPONSE.data.travels)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_jobs: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcreatejob`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem(
          "created_jobs",
          JSON.stringify(RESPONSE.data.createdjobs)
        );
        context.commit(
          "AUTH_FETCHED_ALL_JOBS",
          JSON.stringify(RESPONSE.data.createdjobs)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_medicals: async context => {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchmedicals`, {
          company_id: context.getters.authCompanyData.id,
          year: new Date().getFullYear(),
          month: monthNames[new Date().getMonth()]
        });
        localStorage.setItem(
          "company_all_medicals",
          JSON.stringify(RESPONSE.data)
        ),
          context.commit(
            "AUTH_FETCHED_ALL_MEDICALS",
            JSON.stringify(RESPONSE.data.medicals)
          );
      } catch (error) {
        //
      }
    },
    fetch_all_company_files: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyfile`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "company_files",
          JSON.stringify(RESPONSE.data.companyfiles)
        );
        context.commit(
          "AUTH_FETCHED_ALL_COMPANY_FILES",
          JSON.stringify(RESPONSE.data.companyfiles)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_file_types: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanyfiletype`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "file_types",
          JSON.stringify(RESPONSE.data.companyfiletype)
        );
        context.commit(
          "AUTH_FETCHED_ALL_FILE_TYPES",
          JSON.stringify(RESPONSE.data.companyfiletype)
        );
      } catch (error) {
        //
      }
    },
    fetch_available_services: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchservices`);
        localStorage.setItem(
          "allAvailableServices",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_ALL_AVAILABLE_SERVICES",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_timesheets: async (context, { month, year }) => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchtimesheetbycompany`,
          {
            company_id: context.getters.authCompanyData.id,
            year,
            month
          }
        );
        localStorage.setItem(
          "companyAllTimesheets",
          JSON.stringify(RESPONSE.data.timesheets)
        );
        context.commit(
          "AUTH_FETCHED_ALL_TIMESHEETS",
          JSON.stringify(RESPONSE.data.timesheets)
        );
      } catch (error) {
        //
      }
    },
    fetch_employee_timesheets: async (context, { month, year }) => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeetimesheet`, {
          company_id: context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.authEmployeeDataForCompany.id,
          month,
          year
        });
        localStorage.setItem(
          "employeeTimesheets",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_TIMESHEETS",
          JSON.stringify(RESPONSE.data.timesheets)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_attendance_configuration: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchattendantconfig`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAttendanceConfig",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_ATTENDANCE_CONFIG",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_billing: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanybilling`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllBilling",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_ALL_BILLING",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_roles: async context => {
      try {
        const RESPONSE = await axios.get(`${BASE_URL}fetchallroles`);
        localStorage.setItem("allRoles", JSON.stringify(RESPONSE.data.roles));
        context.commit(
          "AUTH_FETCHED_ALL_ROLES",
          JSON.stringify(RESPONSE.data.roles)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_events: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetcheventbycompany`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllEvents",
          JSON.stringify(RESPONSE.data.events)
        );
        context.commit(
          "AUTH_FETCHED_ALL_EVENTS",
          JSON.stringify(RESPONSE.data.events)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_employee_announcements: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchannouncements`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: context.getters.authEmployeeDataForCompany.id
        });
        localStorage.setItem(
          "companyAllEmployeeAnnouncements",
          JSON.stringify(RESPONSE.data.announcements)
        );
        context.commit(
          "AUTH_FETCHED_ALL_EMPLOYEE_ANNOUNCEMENTS",
          JSON.stringify(RESPONSE.data.announcements)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_company_announcements: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchannouncementbycompany`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id
          }
        );
        localStorage.setItem(
          "companyAllAnnouncements",
          JSON.stringify(RESPONSE.data.announcements)
        );
        context.commit(
          "AUTH_FETCHED_ALL_ANNOUNCEMENTS",
          JSON.stringify(RESPONSE.data.announcements)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_vendors: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchvendor`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllVendors",
          JSON.stringify(RESPONSE.data.vendor)
        );
        context.commit(
          "AUTH_FETCHED_ALL_VENDORS",
          JSON.stringify(RESPONSE.data.vendor)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_check_in: async context => {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchallcompanychecking`,
          {
            company_id:
              context.getters.authCompanyData.id ||
              context.getters.authEmployeeDataForCompany.company_id,
            year: new Date().getFullYear(),
            month: monthNames[new Date().getMonth()]
          }
        );
        localStorage.setItem(
          "companyAllCheckin",
          JSON.stringify(RESPONSE.data)
        );
        context.commit(
          "AUTH_FETCHED_ALL_CHECK_IN",
          JSON.stringify(RESPONSE.data)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_asset_types: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchassettype`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllAssetType",
          JSON.stringify(RESPONSE.data.assettype)
        );
        context.commit(
          "AUTH_FETCHED_ALL_ASSET_TYPE",
          JSON.stringify(RESPONSE.data.assettype)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_employee_documents: async (
      context,
      employeeid = context.getters.validEmployeeId
    ) => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployeedocuments`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id,
          employee_id: employeeid
        });
        localStorage.setItem(
          "employeeDocuments",
          JSON.stringify(RESPONSE.data.employeedocuments)
        );
        context.commit(
          "AUTH_FETCHED_EMPLOYEE_DOCUMENTS",
          JSON.stringify(RESPONSE.data.employeedocuments)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_leave_type: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchleavetype`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllLeaveType",
          JSON.stringify(RESPONSE.data.Employees)
        );
        context.commit(
          "AUTH_FETCHED_ALL_LEAVE_TYPE",
          JSON.stringify(RESPONSE.data.Employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_leave: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchallleave`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAllLeave",
          JSON.stringify(RESPONSE.data.Employees)
        );
        context.commit(
          "AUTH_FETCHED_ALL_LEAVE",
          JSON.stringify(RESPONSE.data.Employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_all_approved_leave: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchapprovedleave`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyApprovedLeave",
          JSON.stringify(RESPONSE.data.Employees)
        );
        context.commit(
          "AUTH_FETCHED_APPROVED_LEAVE",
          JSON.stringify(RESPONSE.data.Employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_assets: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchasset`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyAssets",
          JSON.stringify(RESPONSE.data.Employees)
        );
        context.commit(
          "AUTH_FETCHED_ASSETS",
          JSON.stringify(RESPONSE.data.Employees)
        );
      } catch (error) {
        //
      }
    },
    fetch_candidates: async context => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchemployeeascandidate`,
          {
            company_id: context.getters.authCompanyData.id
          }
        );
        localStorage.setItem(
          "companyCandidates",
          JSON.stringify(RESPONSE.data.candidates)
        );
        context.commit(
          "AUTH_FETCHED_CANDIDATES",
          JSON.stringify(RESPONSE.data.candidates)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_goals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchgoals`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem(
          "companyGoals",
          JSON.stringify(RESPONSE.data.goals)
        );
        context.commit(
          "AUTH_FETCHED_GOALS",
          JSON.stringify(RESPONSE.data.goals)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_departments: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchdepartment`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyDepartments",
          JSON.stringify(RESPONSE.data.departments)
        );
        context.commit(
          "AUTH_FETCHED_DEPARTMENTS",
          JSON.stringify(RESPONSE.data.departments)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_KRAs: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchkra`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem("companyKRAs", JSON.stringify(RESPONSE.data.kpis));
        context.commit("AUTH_FETCHED_KRAS", JSON.stringify(RESPONSE.data.kpis));
      } catch (error) {
        //
      }
    },
    fetch_company_appraisals: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchappraisals`, {
          company_id: context.getters.authCompanyData.id
        });
        localStorage.setItem(
          "companyAppraisals",
          JSON.stringify(RESPONSE.data.appraisals)
        );
        context.commit(
          "AUTH_FETCHED_APPRAISALS",
          JSON.stringify(RESPONSE.data.appraisals)
        );
      } catch (error) {
        //
      }
    },
    fetch_company_employees: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchemployee`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyEmployees",
          JSON.stringify(RESPONSE.data.employees)
        );
        context.commit("AUTH_FETCHED_EMPLOYEES", {
          employees: JSON.stringify(RESPONSE.data.employees),
          status: "fetched"
        });
      } catch (error) {
        //
      }
    },
    fetch_active_company_employees: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchactiveemployees`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        });
        localStorage.setItem(
          "companyEmployees",
          JSON.stringify(RESPONSE.data.employees)
        );
        context.commit("AUTH_FETCHED_EMPLOYEES", {
          employees: JSON.stringify(RESPONSE.data.employees),
          status: "fetched"
        });
      } catch (error) {
        //
      }
    },
    fetch_company_data: async context => {
      try {
        const RESPONSE = await axios.post(`${BASE_URL}fetchcompanydata`, {
          company_id:
            context.getters.authCompanyData.id ||
            context.getters.authEmployeeDataForCompany.company_id
        }, {
          headers: {
            Authorization: `Bearer ${context.state.userToken}`
          }
        });
        localStorage.setItem(
          "companyData",
          JSON.stringify(RESPONSE.data.companydata)
        );
        context.commit("AUTH_FETCHED_COMPANY_DATA", JSON.stringify(RESPONSE.data.companydata));
      } catch (error) {
        //
      }
    },
    refresh_company_data: async (context, refreshData) => {
      try {
        const RESPONSE = await axios.post(
          `${BASE_URL}fetchcompanyservices`,
          {
            user_id: refreshData.user_id,
            company_id:
              refreshData.company_id ||
              context.getters.authEmployeeDataForCompany.company_id
          },
          {
            headers: {
              Authorization: refreshData.token
                ? `Bearer ${refreshData.token}`
                : `Bearer ${context.state.userToken}`
            }
          }
        );
        if (
          RESPONSE.data.login_details &&
          RESPONSE.data.company_details &&
          RESPONSE.data.company_services
        ) {
          localStorage.setItem(
            "userData",
            JSON.stringify(RESPONSE.data.login_details)
          );
          localStorage.setItem(
            "companyData",
            JSON.stringify(RESPONSE.data.company_details)
          );
          localStorage.setItem(
            "companyServices",
            JSON.stringify(RESPONSE.data.company_services)
          );
          localStorage.setItem("subscription_expired", JSON.stringify(false));
          context.commit("AUTH_REFRESH", {
            userData: JSON.stringify(RESPONSE.data.login_details),
            companyData: JSON.stringify(RESPONSE.data.company_details),
            companyServices: JSON.stringify(RESPONSE.data.company_services),
            isExpired: JSON.stringify(false)
          });
        } else {
          localStorage.setItem(
            "companyServices",
            JSON.stringify(RESPONSE.data.company_services)
          );
          localStorage.setItem("subscription_expired", JSON.stringify(true));
          context.commit("SUBSCRIPTION_EXPIRED", {
            isExpired: JSON.stringify(true),
            companyServices: JSON.stringify(RESPONSE.data.company_services)
          });
        }
      } catch (error) {
        // console.log("error", error);
      }
    },
    register_request: async (context, registerData) => {
      // window.grecaptcha.ready(function () {
      //   window.grecaptcha.execute(context.state.captchaSiteKey, { action: 'submit' }).then(async function (token) {
      context.commit("AUTH_LOADING");
      try {
        await axios.post(`${BASE_URL}registercompany`, {
          name: registerData.name,
          company_name: registerData.company_name,
          email: registerData.email,
          phone: registerData.phone,
          password: registerData.password,
          confirm_password: registerData.confirm_password,
          number_of_employees: registerData.number_of_employees,
          role: registerData.role,
          // token
        });
        context.commit("AUTH_REGISTERED");
      } catch (error) {
        let registerError;
        if (window.navigator.onLine) {
          if (error.response.data.error.name) {
            registerError = error.response.data.error.name[0];
          } else if (error.response.data.error.company_name) {
            registerError = error.response.data.error.company_name[0];
          } else if (error.response.data.error.email) {
            registerError = error.response.data.error.email[0];
          } else if (error.response.data.error.phone) {
            registerError = error.response.data.error.phone[0];
          } else if (error.response.data.error.password) {
            registerError = error.response.data.error.password[0];
          } else if (error.response.data.error.subdomain) {
            registerError = error.response.data.error.subdomain[0];
          } else if (error.response.data.error.confirm_password) {
            registerError = error.response.data.error.confirm_password[0];
          } else {
            registerError = "There was an error in registration";
          }
        } else {
          registerError = "Please check your internet connectivity";
        }
        context.commit("TRACK_REGISTER_ERROR", registerError);
        context.commit("AUTH_ERROR");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("companyData");
      }
      //   });
      // });
    },
    auth_request: async (context, loginData) => {
      const d = new Date();
      const year = d.getFullYear();
      let month = d.getMonth() + 1;
      month = month <= 9 ? "0" + month.toString() : month;
      let day = d.getDate();
      day = day <= 9 ? "0" + day.toString() : day;

      const today = `${year.toString()}-${month.toString()}-${day.toString()}`;

      context.commit("AUTH_LOADING");
      try {
        const RESPONSE = await axios.post(`${BASE_URL}login`, {
          email: loginData.email,
          password: loginData.password,
          day: today
        });
        if (RESPONSE.data.user.user_type == "1") {
          const TOKEN = RESPONSE.data.success.token;

          const USER = RESPONSE.data.user;
          const COMPANY = RESPONSE.data.company;
          const MONTHS_LEFT = RESPONSE.data.remainingmonth;
          const two_fa_available = RESPONSE.data.twofa;
          context
            .dispatch("refresh_company_data", {
              user_id: USER.id,
              company_id: COMPANY.id,
              token: TOKEN
            })
            .then(() => {
              context.dispatch("login_request", [
                TOKEN,
                USER,
                COMPANY,
                MONTHS_LEFT,
                two_fa_available
              ]);
            });
        }
        if (RESPONSE.data.user.user_type != "1") {
          if (RESPONSE.data.attendant) {
            if (!RESPONSE.data.attendant.check_out) {
              localStorage.setItem(
                "msTime",
                new Date(RESPONSE.data.attendant.ms_time).getTime()
              );
              context.commit(
                "SET_CHECK_IN_TIME",
                new Date(RESPONSE.data.attendant.ms_time).getTime()
              );
            }
            localStorage.setItem(
              "checkInStatus",
              RESPONSE.data.attendant.check_in_status
            );
            let status =
              RESPONSE.data.attendant.check_in_status == "null"
                ? null
                : RESPONSE.data.attendant.check_in_status;
            context.commit("GET_CHECK_IN_STATUS", status);
          } else {
            localStorage.setItem("checkInStatus", 0);
            context.commit("GET_CHECK_IN_STATUS", 0);
          }

          const TOKEN = RESPONSE.data.success.token;
          const USER = RESPONSE.data.user;
          const EMPLOYEE = RESPONSE.data.employee;
          const COMPANY = RESPONSE.data.company;
          const two_fa_available = RESPONSE.data.twofa;
          context.dispatch("employee_login_request", [
            TOKEN,
            USER,
            EMPLOYEE,
            COMPANY,
            two_fa_available
          ]);
        }
      } catch (error) {
        context.commit("AUTH_ERROR");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("companyData");
      }
    },
    employee_login_request: (context, tokenUserEmployee) => {
      if (tokenUserEmployee[1].verified == "1") {
        localStorage.setItem("userToken", tokenUserEmployee[0]);
        localStorage.setItem("userData", JSON.stringify(tokenUserEmployee[1]));
        localStorage.setItem(
          "employeeDataForCompany",
          JSON.stringify(tokenUserEmployee[2])
        );
        localStorage.setItem(
          "companyData",
          JSON.stringify(tokenUserEmployee[3])
        );
        localStorage.setItem(
          "two_fa_available",
          JSON.stringify(tokenUserEmployee[4])
        );

        context.commit("AUTH_SUCCESS2", [
          tokenUserEmployee[0],
          JSON.stringify(tokenUserEmployee[1]),
          JSON.stringify(tokenUserEmployee[2]),
          JSON.stringify(tokenUserEmployee[3]),
          JSON.stringify(tokenUserEmployee[4])
        ]);
      } else {
        context.commit("AUTH_UNVERIFIED");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("companyData");
      }
    },
    login_request: (context, tokenUserCompany) => {
      if (tokenUserCompany[1].verified == "1") {
        localStorage.setItem("userToken", tokenUserCompany[0]);
        localStorage.setItem("userData", JSON.stringify(tokenUserCompany[1]));
        localStorage.setItem(
          "two_fa_available",
          JSON.stringify(tokenUserCompany[4])
        );
        localStorage.setItem(
          "remaining_months",
          JSON.stringify(tokenUserCompany[3])
        );
        localStorage.setItem(
          "companyData",
          JSON.stringify(tokenUserCompany[2])
        );
        context.commit("AUTH_SUCCESS", [
          tokenUserCompany[0],
          JSON.stringify(tokenUserCompany[1]),
          JSON.stringify(tokenUserCompany[2]),
          JSON.stringify(tokenUserCompany[3]),
          JSON.stringify(tokenUserCompany[4])
        ]);
      } else {
        context.commit("AUTH_UNVERIFIED");
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        localStorage.removeItem("companyData");
      }
    },
    logout_request: async context => {
      try {
        await axios.post(
          `${BASE_URL}logout`,
          {
            employee_id:
              context.getters.userType == 1
                ? context.getters.adminEmployeeData.id
                : context.getters.authEmployeeDataForCompany.id
          },
          {
            headers: {
              Authorization: `Bearer ${context.state.userToken}`
            }
          }
        );
      } catch (error) {
        //
      }
      context.commit("AUTH_LOGOUT");
      localStorage.clear();
    }
  },
  modules: {}
});
