<template>
    <div class="card-body row py-4">
        <div class="col-sm-4" style="align-self: center;">
            <h4>Appraisal Reviews</h4>
        </div>
        <div class="col-sm-8">
            <v-row>
                <v-col md="3">
                    <v-select
                        v-model="filters.departmentName"
                        :items="availableDepartmentsNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select department"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.designationName"
                        :items="availableDesignationNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select designation"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-select
                        v-model="filters.appraisalName"
                        :items="availableAppraisalNames"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        solo
                        dense
                        hint="Select appraisal"
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col md="3">
                    <v-text-field
                    v-model="filters.searchText" 
                    placeholder="Search"
                    messages="Search by employee name" 
                    class="search-employees"
                    solo 
                    dense
                    ></v-text-field>
                </v-col>
            </v-row>
        </div>
        <div class="col-sm-12 py-0">
            <div class="main-content mt-0 px-0 py-0">
                <div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Employee Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Appraisal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Appraisal Duration
                                    </th>
                                    <th class="text-left" scope="col">
                                        Guided Document
                                    </th>
                                    <th class="text-left" scope="col">
                                        Final Score
                                    </th>
                                    <th class="text-left" scope="col">
                                        Rating
                                    </th>
                                    <th class="text-center" scope="col">Begin Appraisal</th>
                                    <th class="text-center" scope="col">
                                        Reviews History
                                    </th>
                                    <th class="text-center" scope="col">
                                        Feedback History
                                    </th>
                                    <th class="text-center" scope="col">Restart Appraisal</th>
                                    <th class="text-center" scope="col">End Appraisal</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(appraisal, index) in filteredCurrentReviewableAppraisals" :key="'employee-appraisal-review-'+index">
                                    <td class="text-left" v-if="singleEmployeeObject(appraisal.employee_id)">{{ singleEmployeeObject(appraisal.employee_id).employee_fristname + ' ' + singleEmployeeObject(appraisal.employee_id).employee_lastname }}</td>
                                    <td class="text-left" v-else>
                                        <i>Employee not found</i>
                                    </td>
                                    <td class="text-left">{{ appraisal.appraisal_name }}</td>
                                    <td v-if="getAppraisalById(appraisal.appraisal_id)" class="text-left">
                                        {{ 
                                            new Date(getAppraisalById(appraisal.appraisal_id).start_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                            + ' - ' +
                                            new Date(getAppraisalById(appraisal.appraisal_id).end_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                        }}
                                        <!-- <br />
                                        <i>Locked in 2 days</i> -->
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No duration specified</i>
                                    </td>
                                    <td v-if="getGuidedDocument(appraisal.appraisal_id)" class="text-left">
                                        <a :href="assets_url + getGuidedDocument(appraisal.appraisal_id)" target="_blank" rel="noopener noreferrer">
                                            View Guided Document
                                        </a>
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No guided document added</i>
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalScore(appraisal.employee_id, appraisal.appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        {{ getEmployeeFinalRating(appraisal.employee_id, appraisal.appraisal_id) }}
                                    </td>
                                    <td v-if="employeeAppraisalIsEnded(appraisal.id)">
                                        <i>Appraisal ended</i>
                                    </td>
                                    <td v-else class="text-center">
                                        <button
                                            @click.prevent="beginAppraisal(appraisal, validEmployeeId, validEmployeeId)"
                                            type="button"
                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                                        >
                                            Begin Appraisal
                                        </button>
                                    </td>
                                    <td class="text-center">
                                        <button v-if="appraisal.final_rating && appraisal.total_score" @click="(current_appraisal_id = appraisal.appraisal_id, current_employee_id = appraisal.employee_id, view_reviews_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            View History
                                        </button>
                                        <div v-else>
                                            <i>Appraisal is unpublished</i>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <button @click="(current_appraisal_id = appraisal.appraisal_id, current_employee_appraisal_id = appraisal.id, current_employee_id = appraisal.employee_id, view_appraisal_feedbacks_dialog = true)" type="button" class="btn btn-secondary btn-sm ripple">
                                            View Feedback
                                        </button>
                                    </td>
                                    <td v-if="employeeAppraisalIsEnded(appraisal.id) === false">
                                        <i>Appraisal started</i>
                                    </td>
                                    <td v-else class="text-center">
                                        <button
                                            v-if="employeeIdsUnderReportingManager.indexOf(parseInt(appraisal.employee_id)) !== -1"
                                            @click.prevent="restartAppraisal(appraisal.id)"
                                            type="button"
                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                                        >
                                            Restart Appraisal
                                        </button>
                                        <i v-else>Not reporting manager</i>
                                    </td>
                                    <td v-if="employeeAppraisalIsEnded(appraisal.id)">
                                        <i>Appraisal ended</i>
                                    </td>
                                    <td v-else class="text-center">
                                        <button
                                            v-if="employeeIdsUnderReportingManager.indexOf(parseInt(appraisal.employee_id)) !== -1"
                                            @click.prevent="endAppraisal(appraisal.id)"
                                            type="button"
                                            class="btn btn-sm btn-secondary text-white btn-raised ripple"
                                            :class="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' ? 'w-100' : null"
                                        >
                                            End Appraisal
                                        </button>
                                        <i v-else>Not reporting manager</i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <!-- Dialog for main appraisal -->
        <v-dialog v-model="run_appraisal_dialog" max-width="1000px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">{{ currentEmployeeAppraisal.appraisal_name }}</span>
                    <span v-if="getAppraisalById(currentEmployeeAppraisal.appraisal_id)" style="float: right;">{{ new Date(getAppraisalById(currentEmployeeAppraisal.appraisal_id).start_date).toUTCString().substring(0, 16) }} - {{ new Date(getAppraisalById(currentEmployeeAppraisal.appraisal_id).end_date).toUTCString().substring(0, 16) }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" class="remove-hint pb-0">
                                    <h4 style="color: #111d5e;">Self Appraisal </h4>
                                </v-col>
                                <v-col v-if="getAppraisalById(currentEmployeeAppraisal.appraisal_id) ? getAppraisalById(currentEmployeeAppraisal.appraisal_id).appraisal_url : null" cols="12" >
                                    <p>
                                        Visit 
                                        <a :href="getAppraisalById(currentEmployeeAppraisal.appraisal_id).appraisal_url" target="_blank">Appraisal URL <i class="fal fa-arrow-up-right-from-square"></i></a>
                                    </p>
                                </v-col>
                                <v-col v-else v-for="(questionnaire, index) in selfAppraisalQuestionnaires" :key="'questionnaire-to-reply'+index" cols="12" >
                                    <span style="color: #111d5e;">{{ questionnaire.question }}</span>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-textarea 
                                            disabled
                                            placeholder="No answer" 
                                            rows="1" 
                                            v-model="questionnaire.response" 
                                            solo 
                                            class="mt-2"></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" >
                                    <span style="color: #111d5e;">Rating</span>
                                    <div class="mt-2">
                                        <v-rating
                                        background-color="warning lighten-1"
                                        color="warning"
                                        dense
                                        empty-icon="far fa-star-sharp"
                                        full-icon="fas fa-star-sharp"
                                        half-icon="fas fa-star-sharp-half-stroke"
                                        hover
                                        length="5"
                                        size="30"
                                        v-model="selfAppraisalRating"
                                        ></v-rating>
                                    </div>
                                </v-col>
                                <v-col cols="12" >
                                    <span style="color: #111d5e;">Comment</span>
                                    <v-textarea v-model="selfAppraisalComment" placeholder="Enter comment" solo class="mt-2"></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <button @click="selfAppraisalButtonLoading ? null : save_appraisal_review()"
                                        type="button"
                                        class="btn btn-secondary mt-2 ripple w-large-device-button w-100"
                                    >
                                        {{ selfAppraisalButtonText }}
                                        <v-icon
                                            :style="!selfAppraisalButtonLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                            <v-row v-if="employeeGoalsInfo.length >= 1">
                                <v-col cols="12" class="remove-hint pb-0 mt-8">
                                    <h4 style="color: #111d5e;">Goals </h4>
                                </v-col>
                                <v-col class="mb-10" cols="12" v-for="(goal, index) in employeeGoalsInfo" :key="'goal-review-'+index">
                                    <v-row>
                                        <v-col cols="12" sm="9" >
                                            <h5 style="color: #111d5e;" class="mb-5">Goal #{{ index + 1 }}: {{ goal.goal_name }}</h5>
                                            <v-row>

                                                <v-col v-if="goal.KRAs.length >= 1" cols="12">
                                                    <v-row v-for="(kra, index) in goal.KRAs" :key="'goal-kra' + index">
                                                        <v-col cols="6">{{ kra.kra }}</v-col>
                                                        <v-col cols="6">
                                                            <span v-if="getEmployeeKRAInfo(kra.id, current_employee_id) && getEmployeeKRAInfo(kra.id, current_employee_id).is_completed == 1" class="badge badge-success text-white">COMPLETED</span>
                                                            <span v-else class="badge badge-warning text-white">INCOMPLETE</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" sm="3" >
                                            <div class="ul-widget-s5__progress pr-0">
                                                <div class="progress">
                                                    <div 
                                                    class="progress-bar bg-secondary" 
                                                    role="progressbar" 
                                                    :style="
                                                    'width: ' 
                                                    + 
                                                    getGoalCompletionPercentage(goal.goal_id, current_employee_id) 
                                                    + 
                                                    '%'" 
                                                    :aria-valuenow="getGoalCompletionPercentage(goal.goal_id, current_employee_id) " 
                                                    aria-valuemin="0" 
                                                    aria-valuemax="100">{{ 
                                                        getGoalCompletionPercentage(goal.goal_id, current_employee_id) 
                                                    }}%
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" >
                                            <span style="color: #111d5e;">Rating</span>
                                            <div class="mt-2">
                                                <v-rating
                                                background-color="warning lighten-1"
                                                color="warning"
                                                dense
                                                empty-icon="far fa-star-sharp"
                                                full-icon="fas fa-star-sharp"
                                                half-icon="fas fa-star-sharp-half-stroke"
                                                hover
                                                length="5"
                                                size="30"
                                                v-model="goal.rating"
                                                ></v-rating>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" >
                                            <span style="color: #111d5e;">Comment</span>
                                            <v-textarea v-model="goal.comment" placeholder="Enter comment" solo class="mt-2"></v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <button @click="goal.buttonLoading ? null : save_goal_review(goal)"
                                                type="button"
                                                class="btn btn-secondary mt-2 ripple w-large-device-button w-100"
                                            >
                                                {{ goal.buttonText }}
                                                <v-icon
                                                    :style="!goal.buttonLoading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                                >
                                                    fal fa-circle-notch fa-spin
                                                </v-icon>
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-10">
                                <v-col cols="12">
                                    <h5 v-if="singleEmployeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId)">Overall score for {{ singleEmployeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId).employee_fristname + ' ' + singleEmployeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId).employee_lastname }}</h5>
                                    <h5 v-else>Overall score</h5>
                                </v-col>
                                <v-col cols="12">
                                    <div class="main-content mt-0 px-0 py-0">
                                        <div class="table-responsive">
                                            <table class="table mb-2">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" scope="col">
                                                            Module
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Score
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-left">Appraisal</td>
                                                        <td class="text-left">{{ selfAppraisalRating }} of 5</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">Goal</td>
                                                        <td class="text-left">{{ averageGoalScore }} of 5</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-left">Final Score</td>
                                                        <td class="text-left">{{ (parseFloat(selfAppraisalRating) + averageGoalScore) / 2 }} of 5</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="run_appraisal_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for main appraisal -->

        <!-- Dialog for viewing reviews -->
        <v-dialog v-model="view_reviews_dialog" max-width="900px">
            <v-card>
                <v-card-title class="text-left d-block">
                    <span class="headline">Reviews {{ getAppraisalById(current_appraisal_id) ? `[${getAppraisalById(current_appraisal_id).appraisal_name}]` : '' }}</span>
                    <span  class="headline float-right">
                        <button @click="triggerPrint()" style="float: right;" type="button"
                        class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple">
                            Print
                        </button>
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-left" scope="col">
                                        Reviewer Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Appraisal Name
                                    </th>
                                    <th class="text-left" scope="col">
                                        Appraisal Duration
                                    </th>
                                    <th class="text-left" scope="col">
                                        Final Score
                                    </th>
                                    <th class="text-left" scope="col">
                                        Rating
                                    </th>
                                    <th class="text-left" scope="col">
                                        Print Breakdown
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left" v-if="singleEmployeeObject(current_employee_id)">
                                        {{ singleEmployeeObject(current_employee_id).employee_fristname + ' ' + singleEmployeeObject(current_employee_id).employee_lastname }}
                                    </td>
                                    <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                                        {{ getAppraisalById(current_appraisal_id).appraisal_name }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>Appraisal not found</i>
                                    </td>
                                    <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                                        {{ 
                                            new Date(getAppraisalById(current_appraisal_id).start_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                            + ' - ' +
                                            new Date(getAppraisalById(current_appraisal_id).end_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                        }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No duration specified</i>
                                    </td>
                                    <td class="text-left">
                                        {{ getReviewerFinalScore(current_employee_id, current_employee_id, current_appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        {{ getReviewerFinalRating(current_employee_id, current_employee_id, current_appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        <i @click="printAppraisalReview(current_appraisal_id, current_employee_id, current_employee_id)" class="fal fa-print fa-2x cursor-pointer" style='color: #069;'></i>
                                    </td>
                                </tr>
                                <tr v-for="(reviewer, index) in getEmployeeReviewers(current_appraisal_id, current_employee_id)" :key="'reviewer' + index">
                                    <td class="text-left">
                                        {{ reviewer.employee_fristname + ' ' + reviewer.employee_lastname }}
                                    </td>
                                    <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                                        {{ getAppraisalById(current_appraisal_id).appraisal_name }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>Appraisal not found</i>
                                    </td>
                                    <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                                        {{ 
                                            new Date(getAppraisalById(current_appraisal_id).start_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                            + ' - ' +
                                            new Date(getAppraisalById(current_appraisal_id).end_date)
                                            .toUTCString()
                                            .substring(0, 16) 
                                        }}
                                    </td>
                                    <td v-else class="text-left">
                                        <i>No duration specified</i>
                                    </td>
                                    <td class="text-left">
                                        {{ getReviewerFinalScore(current_employee_id, reviewer.reviewer_id, current_appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        {{ getReviewerFinalRating(current_employee_id, reviewer.reviewer_id, current_appraisal_id) }}
                                    </td>
                                    <td class="text-left">
                                        <i @click="printAppraisalReview(current_appraisal_id, current_employee_id, reviewer.reviewer_id)" class="fal fa-print fa-2x cursor-pointer" style='color: #069;'></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_reviews_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing reviews -->

        <!-- Dialog for viewing appraisal feedback -->
        <v-dialog v-model="view_appraisal_feedbacks_dialog" max-width="1200px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">All Feedbacks</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="card-body row py-4">
                            <div class="col-sm-12 py-0">
                                <div class="main-content mt-0 px-0 py-0">
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-left" scope="col">
                                                            Initial Commenter
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Feedback Type
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Comment
                                                        </th>
                                                        <th class="text-left" scope="col">
                                                            Response
                                                        </th>
                                                        <th v-if="(
                                                            employeeAppraisalIsEnded(current_employee_appraisal_id) === false
                                                            &&
                                                            employeeIsUnderReportingManager(current_employee_id)
                                                            )" class="text-center" scope="col">
                                                            Add Response
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="feedback in getFeedbacksByAppraisalId(current_employee_id, current_appraisal_id)" :key="feedback.id">
                                                        <td class="text-left">
                                                            {{
                                                                singleEmployeeObject(feedback.employee_id)
                                                                ?
                                                                (
                                                                    singleEmployeeObject(feedback.employee_id).employee_fristname
                                                                    + ' ' +
                                                                    singleEmployeeObject(feedback.employee_id).employee_lastname
                                                                    )
                                                                    :
                                                                    'Employee not found'
                                                                }}
                                                        </td>
                                                        
                                                        <td v-if="feedback.feedback_type == 1" class="text-left">Satisfied</td>
                                                        <td v-else-if="feedback.feedback_type == 2" class="text-left">Not Satisfied</td>
                                                        <td v-else class="text-left">Neutral</td>

                                                        <td v-if="feedback.comment" class="text-left">{{ feedback.comment }} {{ 
                                                            '('
                                                            +
                                                            new Date(feedback.created_at).toDateString() 
                                                            + 
                                                            ' ' 
                                                            + 
                                                            new Date(feedback.created_at).toLocaleTimeString()
                                                            +
                                                            ')' }}
                                                        </td>
                                                        <td v-else class="text-left">
                                                            <i>No comment added</i>
                                                        </td>

                                                        <td v-if="getFeedbackResponses(feedback.feedbackcomment_id).length >= 1" class="text-left">
                                                            <ul>
                                                                <li 
                                                                v-for='(response, index) in getFeedbackResponses(feedback.feedbackcomment_id)'
                                                                :key='`response-`+index'
                                                                >
                                                                    {{
                                                                        response.response
                                                                        +
                                                                        ' ('
                                                                        +
                                                                        new Date(response.updated_at).toDateString() 
                                                                        + 
                                                                        ' ' 
                                                                        + 
                                                                        new Date(response.updated_at).toLocaleTimeString()
                                                                        +
                                                                        ')'
                                                                    }}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                        <td v-else class="text-left">
                                                            <i>No response added</i>
                                                        </td>
                                                        
                                                        
                                                        <td v-if="(
                                                            employeeAppraisalIsEnded(current_employee_appraisal_id) === false
                                                            &&
                                                            employeeIsUnderReportingManager(current_employee_id)
                                                            )" class="text-center">
                                                            <i @click="launchEditResponse(feedback.feedbackcomment_id)" class="fas fa-reply cursor-pointer"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="view_appraisal_feedbacks_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for viewing appraisal feedback -->

        <!-- Dialog for adding appraisal feedback -->
        <v-dialog v-model="add_appraisal_feedback_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Appraisal Feedback</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" >
                                    <span style="color: #111d5e;">Feedback Type <i class="fal fa-asterisk" style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"></i></span>
                                    <v-select 
                                        v-model="appraisal_feedback_form.feedback_type" 
                                        placeholder="Feedback type"
                                        solo 
                                        class="mt-2" 
                                        :items="['Satisfied', 'Not Satisfied', 'Neutral']" 
                                        dense 
                                        :full-width="true"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" >
                                    <span style="color: #111d5e;">Comment</span>
                                    <v-textarea v-model="appraisal_feedback_form.comment" placeholder="Enter comment" solo class="mt-2"></v-textarea>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <button
                                        @click="appraisal_feedback_form.button_loading ? null : submit_appraisal_feedback()"
                                        type="button"
                                        class="btn btn-secondary m-1 ripple w-25 px-0"
                                    >
                                        {{ appraisal_feedback_form.button_text }}
                                        <v-icon
                                            :style="!appraisal_feedback_form.button_loading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_appraisal_feedback_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding appraisal feedback -->

        <!-- Dialog for adding feedback response -->
        <v-dialog v-model="add_feedback_response_dialog" max-width="600px">
            <v-card>
                <v-card-title class="d-block">
                    <span class="headline">Add Feedback Response</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <div class="container-fluid">
                            <v-row>
                                <v-col cols="12" >
                                    <span style="color: #111d5e;">Comment</span>
                                    <v-textarea v-model="feedback_response_form.comment" placeholder="Enter comment" solo class="mt-2"></v-textarea>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <button
                                        @click="feedback_response_form.button_loading ? null : submit_feedback_response()"
                                        type="button"
                                        class="btn btn-secondary m-1 ripple w-25 px-0"
                                    >
                                        {{ feedback_response_form.button_text }}
                                        <v-icon
                                            :style="!feedback_response_form.button_loading ? 'display: none;' : 'color: #fff; padding: 0px 10px; font-size: 16px;'"
                                        >
                                            fal fa-circle-notch fa-spin
                                        </v-icon>
                                    </button>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_feedback_response_dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Dialog for adding feedback response -->

        <!-- PDF document for printing all reviews -->
        <div id="print" class="d-none d-print-block">
            <v-row class="py-2 px-2" v-if="singleEmployeeObject(current_employee_id)">
                <v-col cols="8">
                    <v-row>
                        <v-col class="text-center pr-0" cols="3">
                            <img style="border-radius: 100%;" class="w-75"
                            :src="
                                singleEmployeeObject(current_employee_id)
                                ?
                                    (
                                        singleEmployeeObject(current_employee_id).profile_image_url 
                                        ? 
                                        (assets_url + singleEmployeeObject(current_employee_id).profile_image_url) 
                                        : 
                                        '/assets/images/default_profile_img.png'
                                    )
                                :
                                '/assets/images/default_profile_img.png'
                            "
                            alt="" />
                        </v-col>
                        <v-col class="pl-0" cols="8">
                            <h4 class="mt-4">{{ singleEmployeeObject(current_employee_id).employee_fristname + ' ' + singleEmployeeObject(current_employee_id).employee_lastname }}</h4>
                            <p class="mb-0 mt-3">
                            {{
                                singleEmployeeObject(current_employee_id).employee_designation ? singleEmployeeObject(current_employee_id).employee_designation : 'No designation specified'
                            }} - {{ singleEmployeeObject(current_employee_id).department_id ? get_dept_name(singleEmployeeObject(current_employee_id).department_id) : 'No assigned department' }}</p>
                            <p>
                                <a :href="'mail:' + singleEmployeeObject(current_employee_id).employee_email" target="_blank">
                                    {{ singleEmployeeObject(current_employee_id).employee_email }}
                                </a>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <h3 class="headline text-right">
                        Reviews {{ getAppraisalById(current_appraisal_id) ? `[${getAppraisalById(current_appraisal_id).appraisal_name}]` : '' }}
                    </h3>
                </v-col>
            </v-row>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-left" scope="col">
                            Reviewer Name
                        </th>
                        <th class="text-left" scope="col">
                            Appraisal Name
                        </th>
                        <th class="text-left" scope="col">
                            Appraisal Duration
                        </th>
                        <th class="text-left" scope="col">
                            Final Score
                        </th>
                        <th class="text-left" scope="col">
                            Rating
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left" v-if="singleEmployeeObject(current_employee_id)">
                            {{ singleEmployeeObject(current_employee_id).employee_fristname + ' ' + singleEmployeeObject(current_employee_id).employee_lastname }}
                        </td>
                        <td class="text-left" v-else>
                            <i>Employee not found</i>
                        </td>
                        <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                            {{ getAppraisalById(current_appraisal_id).appraisal_name }}
                        </td>
                        <td v-else class="text-left">
                            <i>Appraisal not found</i>
                        </td>
                        <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                            {{ 
                                new Date(getAppraisalById(current_appraisal_id).start_date)
                                .toUTCString()
                                .substring(0, 16) 
                                + ' - ' +
                                new Date(getAppraisalById(current_appraisal_id).end_date)
                                .toUTCString()
                                .substring(0, 16) 
                            }}
                        </td>
                        <td v-else class="text-left">
                            <i>No duration specified</i>
                        </td>
                        <td class="text-left">
                            {{ getReviewerFinalScore(current_employee_id, authEmployeeDataForCompany.id, current_appraisal_id) }}
                        </td>
                        <td class="text-left">
                            {{ getReviewerFinalRating(current_employee_id, authEmployeeDataForCompany.id, current_appraisal_id) }}
                        </td>
                    </tr>
                    <tr v-for="(reviewer, index) in getEmployeeReviewers(current_appraisal_id, current_employee_id)" :key="'reviewer' + index">
                        <td class="text-left">
                            {{ reviewer.employee_fristname + ' ' + reviewer.employee_lastname }}
                        </td>
                        <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                            {{ getAppraisalById(current_appraisal_id).appraisal_name }}
                        </td>
                        <td v-else class="text-left">
                            <i>Appraisal not found</i>
                        </td>
                        <td v-if="getAppraisalById(current_appraisal_id)" class="text-left">
                            {{ 
                                new Date(getAppraisalById(current_appraisal_id).start_date)
                                .toUTCString()
                                .substring(0, 16) 
                                + ' - ' +
                                new Date(getAppraisalById(current_appraisal_id).end_date)
                                .toUTCString()
                                .substring(0, 16) 
                            }}
                        </td>
                        <td v-else class="text-left">
                            <i>No duration specified</i>
                        </td>
                        <td class="text-left">
                            {{ getReviewerFinalScore(validEmployeeId, reviewer.reviewer_id, current_appraisal_id) }}
                        </td>
                        <td class="text-left">
                            {{ getReviewerFinalRating(validEmployeeId, reviewer.reviewer_id, current_appraisal_id) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- PDF document for printing all reviews -->
        
        <!-- PDF document for printing all review information -->
        <div id="printBreakdown" class="d-none d-print-block">
            <v-row class="py-2 px-2" v-if="singleEmployeeObject(current_employee_id)">
                <v-col cols="8">
                    <v-row>
                        <v-col class="text-center pr-0" cols="3">
                            <img style="border-radius: 100%;" class="w-75"
                            :src="
                                singleEmployeeObject(current_employee_id)
                                ?
                                    (
                                        singleEmployeeObject(current_employee_id).profile_image_url 
                                        ? 
                                        (assets_url + singleEmployeeObject(current_employee_id).profile_image_url) 
                                        : 
                                        '/assets/images/default_profile_img.png'
                                    )
                                :
                                '/assets/images/default_profile_img.png'
                            "
                            alt="" />
                        </v-col>
                        <v-col class="pl-0" cols="8">
                            <h4 class="mt-2">{{ singleEmployeeObject(current_employee_id).employee_fristname + ' ' + singleEmployeeObject(current_employee_id).employee_lastname }}</h4>
                            <p class="mb-0 mt-3">
                            {{
                                singleEmployeeObject(current_employee_id).employee_designation ? singleEmployeeObject(current_employee_id).employee_designation : 'No designation specified'
                            }} - {{ singleEmployeeObject(current_employee_id).department_id ? get_dept_name(singleEmployeeObject(current_employee_id).department_id) : 'No assigned department' }}</p>
                            <p>
                                <a :href="'mail:' + singleEmployeeObject(current_employee_id).employee_email" target="_blank">
                                    {{ singleEmployeeObject(current_employee_id).employee_email }}
                                </a>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <h3 class="headline text-right">
                        Review {{ getAppraisalById(current_appraisal_id) ? `[${getAppraisalById(current_appraisal_id).appraisal_name}]` : '' }}
                    </h3>
                    <p v-if="singleEmployeeObject(current_reviewer_id)" class="text-right">Reviewer: <span style="font-weight: bold;">{{ singleEmployeeObject(current_reviewer_id).employee_fristname + ' ' + singleEmployeeObject(current_reviewer_id).employee_lastname }}</span></p>
                </v-col>
            </v-row>
            <v-row class="pt-3">
                <v-col cols="12">
                    <div class="container-fluid">
                        <v-row>
                            <v-col cols="12" class="remove-hint pb-0">
                                <h4 style="color: #111d5e;">Self Appraisal </h4>
                            </v-col>
                            <v-col v-for="(questionnaire, index) in selfAppraisalQuestionnaires" :key="'questionnaire-to-reply'+index" cols="12" >
                                <span style="color: #111d5e;"><span style="font-weight: bold;">Question #{{index+1}}: </span>{{ questionnaire.question }}</span>
                                <v-row>
                                    <v-col cols="12">
                                        <p class="mt-2">
                                            <span style="color: #111d5e; font-weight: bold;">Response: </span>
                                            <span v-if="questionnaire.response">
                                                {{ questionnaire.response }}
                                            </span>
                                            <span v-else>
                                                <i>No response added</i>
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                                
                            </v-col>
                            <v-col cols="12" >
                                <div class="mt-2">
                                    <span style="color: #111d5e; font-weight: bold;">Rating: </span>
                                    <img 
                                    v-for="n in parseInt(selfAppraisalRating)" 
                                    :key="n"
                                    src="/img/star-filled.svg" 
                                    style="
                                        height: 20px;
                                    " 
                                    class="mr-1">
                                    <img 
                                    v-for="n in (5-parseInt(selfAppraisalRating))" :key="n+'_'"
                                    src="/img/star.svg" 
                                    style="
                                        height: 20px;
                                    " 
                                    class="mr-1">
                                </div>
                            </v-col>
                            <v-col cols="12" >
                                <p class="mt-2">
                                    <span style="color: #111d5e; font-weight: bold;">Comment: </span>
                                    <span v-if="selfAppraisalComment">
                                        {{ selfAppraisalComment }}
                                    </span>
                                    <span v-else>
                                        <i>No comment added</i>
                                    </span>
                                </p>
                            </v-col>
                        </v-row>
                        <v-row v-if="employeeGoalsInfo.length >= 1">
                            <v-col cols="12" class="remove-hint pb-0 mt-8">
                                <h4 style="color: #111d5e;">Goals </h4>
                            </v-col>
                            <v-col class="mb-10" cols="6" v-for="(goal, index) in employeeGoalsInfo" :key="'goal-review-'+index">
                                <v-row>
                                    <v-col cols="12" >
                                        <h5 style="color: #111d5e;" class="mb-2">Goal #{{ index + 1 }}: {{ goal.goal_name }}</h5>
                                        <v-row>
                                            <v-col cols="12" class="mb-4">
                                                <div class="ul-widget-s5__progress pr-0">
                                                    <div class="progress">
                                                        <div class="progress-bar bg-secondary" role="progressbar" :style="'width: ' + getGoalCompletionPercentage(goal.goal_id, current_employee_id)  + '%'" :aria-valuenow="getGoalCompletionPercentage(goal.goal_id, current_employee_id) " aria-valuemin="0" aria-valuemax="100">{{ getGoalCompletionPercentage(goal.goal_id, current_employee_id)  }}%</div>
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col v-if="goal.KRAs.length >= 1" cols="12" class="mb-2">
                                                <v-row v-for="(kra, index) in goal.KRAs" :key="'goal-kra' + index">
                                                    <v-col cols="8">{{ kra.kra }}</v-col>
                                                    <v-col cols="4" class="text-right" style="align-self: center;">
                                                        <span v-if="getEmployeeKRAInfo(kra.id, current_employee_id) && getEmployeeKRAInfo(kra.id, current_employee_id).is_completed == 1" class="badge badge-success text-white">COMPLETED</span>
                                                        <span v-else class="badge badge-warning text-white">INCOMPLETE</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" class="remove-hint mb-2">
                                        <div class="mt-2">
                                            <span style="color: #111d5e; font-weight: bold;">Rating: </span>
                                            <img 
                                            v-for="n in parseInt(goal.rating)" :key="n"
                                            src="/img/star-filled.svg" 
                                            style="height: 20px;" 
                                            class="mr-1">
                                            <img 
                                            v-for="n in (5-parseInt(goal.rating))" :key="n+'_'"
                                            src="/img/star.svg" 
                                            style="height: 20px;" 
                                            class="mr-1">
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="remove-hint mb-2">
                                        <p class="mt-2">
                                            <span style="color: #111d5e; font-weight: bold;">Comment: </span>
                                            <span v-if="goal.comment">
                                                {{ goal.comment }}
                                            </span>
                                            <span v-else>
                                                <i>No comment added</i>
                                            </span>
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="pt-5">
                            <v-col cols="12">
                                <h5>Overall score for {{ employeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId).employee_fristname + ' ' + employeeObject(currentEmployeeAppraisal ? currentEmployeeAppraisal.employee_id : validEmployeeId).employee_lastname }}</h5>
                            </v-col>
                            <v-col cols="12">
                                <div class="main-content mt-0 px-0 py-0">
                                    <div class="table-responsive">
                                        <table class="table mb-2">
                                            <thead>
                                                <tr>
                                                    <th class="text-left" scope="col">
                                                        Module
                                                    </th>
                                                    <th class="text-left" scope="col">
                                                        Score
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-left">Appraisal</td>
                                                    <td class="text-left">{{ selfAppraisalRating }} of 5</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">Goal</td>
                                                    <td class="text-left">{{ averageGoalScore }} of 5</td>
                                                </tr>
                                                <tr>
                                                    <td class="text-left">Final Score</td>
                                                    <td class="text-left">{{ (parseFloat(selfAppraisalRating) + averageGoalScore) / 2 }} of 5</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- PDF document for printing all review information -->
    </div>
</template>

<script>
import { 
    mapActions, 
    mapGetters 
} from 'vuex';
import { 
    BASE_URL,
    ASSETS_URL 
} from '@/main';
import axios from 'axios';

export default {
    props: {
        getTrueEmployeeAppraisals: Function,
        getKRAsInGoal: Function,
        singleEmployeeObject: Function,
        getAppraisalById: Function,
        getEmployeeFinalScore: Function,
        getEmployeeFinalRating: Function,
        getEmployeeAppraisalReview: Function,
        getResponseByQuestionId: Function,
        getEmployeeGoalsInfo: Function,
        getEmployeeKRAInfo: Function,
        getReviewerFinalScore: Function,
        getReviewerFinalRating: Function,
        getEmployeeReviewers: Function,
        getFeedbacksByAppraisalId: Function,
        getFeedbackResponses: Function,
        employeeObject: Function,
        get_dept_name: Function,
        employeeAppraisalIsEnded: Function,
        employeeIsUnderReportingManager: Function,
        getGoalCompletionPercentage: Function,
        employeeIdsUnderReportingManager: Array,
        availableDepartmentsNames: Array,
        availableDesignationNames: Array,
        employeeIdsOfReviewees: Array
    },
    data() {
        return {
            view_appraisal_feedbacks_dialog: false,
            add_appraisal_feedback_dialog: false,
            add_feedback_response_dialog: false,
            appraisal_feedback_form: {
                feedback_type: '',
                comment: '',
                button_loading: false,
                button_text: 'Submit Feedback',
            },
            feedback_response_form: {
                commentId: null,
                comment: '',
                button_loading: false,
                button_text: 'Submit Response',
            },

            currentEmployeeAppraisal: {},
            selfAppraisalComment: '',
            selfAppraisalRating: 0,

            selfAppraisalQuestionnaires: [],
            employeeGoalsInfo: [],

            current_appraisal_id: null,
            current_reviewer_id: null,
            current_employee_id: null,
            current_employee_appraisal_id: null,
            run_appraisal_dialog: false,
            view_reviews_dialog: false,

            add_reviewers_to_appraisal_text: "Submit",
            add_reviewers_to_appraisal_submit: false,
            selfAppraisalButtonText: 'Submit Review',
            selfAppraisalButtonLoading: false,

            filters: {
                departmentName: 'All',
                designationName: 'All',
                appraisalName: 'All',
                searchText: ''
            }
        }
    },
    computed: {
        ...mapGetters([
            'authCompanyData',
            'authEmployeeReporters',
            'authCompanyReviewers',
            'authCompanyQuestionnaires',
            'authCompanyGoalsInAppraisals',
            'authCompanyAppraisals',
            'authEmployeeDataForCompany',
            'authDesignations',
            'authCompanyDepartmentEmployees',
            'validEmployeeId',
        ]),
        assets_url() {
            return ASSETS_URL;
        },
        filteredQuestionnaires() {
            let _this = this, result = [];
            result = _this.authCompanyQuestionnaires.filter(q => q.appraisal_id == _this.current_appraisal_id);
            return result;
        },
        averageGoalScore() {
            let _this = this, value = 0;
            _this.employeeGoalsInfo.forEach(goal => {
                value += parseFloat(goal.rating);
            });
            value = value/_this.employeeGoalsInfo.length;
            return value || 0;
        },
        currentReviewables() {
            let _this = this, result = [];
            result = _this.employeeIdsOfReviewees.map(revieweeId => {
                let allEmployeeAppraisals = _this.getTrueEmployeeAppraisals(revieweeId).filter(appraisal => {
                    return _this.reviewerIsInAppraisalByEmployeeId(_this.validEmployeeId, appraisal.employee_id, appraisal.appraisal_id) === true || _this.employeeIdsUnderReportingManager.indexOf(parseInt(appraisal.employee_id)) !== -1;
                });
                allEmployeeAppraisals = allEmployeeAppraisals.map(appraisal => {
                    let appraisalQuestionnaires = _this.authCompanyQuestionnaires.filter(q => q.appraisal_id == appraisal.appraisal_id);
                    let appraisalGoals = _this.getGoalsInAppraisal(appraisal.appraisal_id);
                    appraisalGoals = appraisalGoals.map(goal => {
                        let appraisalKRAs = _this.getKRAsInGoal(goal.id)
                        return {
                            ...goal,
                            kras: appraisalKRAs
                        }
                    });
                    return {
                        ...appraisal,
                        questionnaires: appraisalQuestionnaires,
                        goals: appraisalGoals
                    }
                });

                return allEmployeeAppraisals;
            });
            return result;
        },
        currentReviewableAppraisals() {
            let _this = this, result = [];
            _this.currentReviewables.forEach(reviewableUser => {
                if (reviewableUser.length >= 1) {
                    reviewableUser.forEach(appraisal => {
                        result.splice(result.length, 0, appraisal);
                    });
                }
            });
            return result;
        },
        filteredCurrentReviewableAppraisals() {
            const _this = this;

            // Search and filter by department name
            let result = _this.currentReviewableAppraisals.filter(CRA => {
                if (_this.filters.departmentName === 'All') {
                    // If all department should show
                    return true;
                } else {
                    // If filtering by department name
                    let employee = _this.singleEmployeeObject(CRA.employee_id);
                    let primaryEmployeeDepartment = _this.get_dept_name(employee.department_id);
                    let secondaryEmployeeDepartments = _this.authCompanyDepartmentEmployees.filter(EMPLOYEE_DEPARTMENT => EMPLOYEE_DEPARTMENT.employee_id == CRA.employee_id);
                    let secondaryEmployeeDepartmentNames = secondaryEmployeeDepartments.map(EMPLOYEE_DEPARTMENT => _this.get_dept_name(EMPLOYEE_DEPARTMENT.department_id));
                    let allEmployeeDepartments = [
                        primaryEmployeeDepartment,
                        ...secondaryEmployeeDepartmentNames
                    ];
                    return allEmployeeDepartments.indexOf(_this.filters.departmentName) !== -1;
                }
            });
            // Search and filter by department name

            // Search and filter by designation name
            result = result.filter(CRA => {
                if (_this.filters.designationName === 'All') {
                    // If all designation should show
                    return true;
                } else {
                    // If filtering by designation name
                    let employee = _this.singleEmployeeObject(CRA.employee_id);
                    let employeeDesignation = employee.employee_designation;
                    return employeeDesignation === _this.filters.designationName;
                }
            });
            // Search and filter by designation name

            // Search and filter by appraisal name
            result = result.filter(CRA => {
                if (_this.filters.appraisalName === 'All') {
                    // If all appraisals should show
                    return true;
                } else {
                    // If filtering by appraisal name
                    return CRA.appraisal_name.indexOf(_this.filters.appraisalName) !== -1;
                }
            });
            // Search and filter by appraisal name

            // Search and filter by employee name
            result = result.filter(CRA => {
                let employee = _this.singleEmployeeObject(CRA.employee_id);
                let employeeName = employee ? `${employee.employee_fristname} ${employee.employee_lastname}` : null;
                return employeeName.toLowerCase().indexOf(_this.filters.searchText.toLowerCase()) !== -1;
            });
            // Search and filter by employee name

            return result;
        },
        availableAppraisalNames() {
            const _this = this;
            let appraisalNames = ['All'];
            _this.currentReviewableAppraisals.forEach(CRA => {
                if (appraisalNames.indexOf(CRA.appraisal_name) === -1) {
                    appraisalNames.splice(appraisalNames.length, 0, CRA.appraisal_name);
                }
            });
            return appraisalNames;
        }
    },
    methods: {
        ...mapActions([
            'fetch_employee_appraisal_reviews',
            'fetch_employee_goal_reviews',
            'fetch_company_appraisal_feedbacks',
            'fetch_employee_appraisals'
        ]),
        save_goal_review(goal) {
            let _this = this;
            goal.buttonText = "";
            goal.buttonLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addgoalreviews`, {
                    goal_id: goal.goal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: goal.employee_id,
                    reviewer_id: _this.validEmployeeId,
                    rating: goal.rating, 
                    comment: goal.comment
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Review added successfully",
                    });
                    _this.fetch_employee_goal_reviews();
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Review couldn't be submitted",
                    });
                })
                .then(function () {
                    goal.buttonLoading = false;
                    goal.buttonText = "Submit";
                });
        },
        save_appraisal_review() {
            let _this = this;
            _this.selfAppraisalButtonText = "";
            _this.selfAppraisalButtonLoading = true;

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            axios
                .post(`${BASE_URL}addappraisalreviews`, {
                    appraisal_id: _this.current_appraisal_id,
                    company_id: _this.authCompanyData.id,
                    employee_id: _this.currentEmployeeAppraisal.employee_id,
                    reviewer_id: _this.validEmployeeId,
                    rating: _this.selfAppraisalRating, 
                    comment: _this.selfAppraisalComment
                })
                .then(function () {
                    Toast.fire({
                        icon: "success",
                        title: "Review added successfully",
                    });
                    _this.fetch_employee_appraisal_reviews();
                })
                .catch(function () {
                    // let errors = error.response.data.error || null;
                    Toast.fire({
                        icon: "error",
                        title: "Review couldn't be submitted",
                    });
                })
                .then(function () {
                    _this.selfAppraisalButtonLoading = false;
                    _this.selfAppraisalButtonText = "Submit Review";
                });
        },
        beginAppraisal(EA, validEmployeeId, reviewerId) {
            const _this = this;
            _this.selfAppraisalComment = "";
            _this.selfAppraisalRating = 0;
            _this.current_appraisal_id = EA.appraisal_id; 
            _this.current_employee_id = EA.employee_id;
            _this.currentEmployeeAppraisal = EA;
            let employeeAppraisalReview = _this.getEmployeeAppraisalReview(EA.appraisal_id, EA.employee_id, validEmployeeId);
            if (employeeAppraisalReview) {
                _this.selfAppraisalComment = employeeAppraisalReview.comment;
                _this.selfAppraisalRating = employeeAppraisalReview.rating ? parseInt(employeeAppraisalReview.rating) : 0;
            }
            _this.selfAppraisalQuestionnaires = _this.filteredQuestionnaires.map(FQ => {
                let responseObj = _this.getResponseByQuestionId(FQ.id, EA.employee_id);
                return {
                    questionnaireId: FQ.id,
                    question: FQ.question,
                    response: responseObj ? responseObj.response : '',
                    buttonText: 'Submit',
                    buttonLoading: false
                }
            });
            _this.employeeGoalsInfo = _this.getEmployeeGoalsInfo(_this.current_appraisal_id, EA.employee_id, reviewerId);
            _this.run_appraisal_dialog = true;
        },
        getGuidedDocument(appraisalId) {
            let _this = this, result = [], value = null;
            result = _this.authCompanyAppraisals.filter(appraisal => appraisal.id == appraisalId);
            if (result.length >= 1) {
                if (result[0].guided_document_url) {
                    value = result[0].guided_document_url;
                }
            }
            return value;
        },
        getGoalsInAppraisal(appraisal_id) {
            let _this = this,
                result = [];
            result = _this.authCompanyGoalsInAppraisals.filter(goal => {
                return goal.appraisal_id == appraisal_id
            });
            return result;
        },
        reviewerIsInAppraisalByEmployeeId(reviewer_id, employee_id, appraisal_id) {
            let _this = this,
                truth = false;
            _this.authCompanyReviewers.forEach((item) => {
                if (item.appraisal_id == appraisal_id && item.reviewer_id == reviewer_id && item.employee_id == employee_id) {
                    truth = true;
                }
            });
            return truth;
        },
        triggerPrint() {
            let _this = this;
            setTimeout(() => {
                _this.print();
            }, 1000);
        },
        async print() {
            let _this = this;
            await _this.$htmlToPaper('print', this.mixinPrintPortraitOptions);
        },
        printAppraisalReview(appraisal_id, validEmployeeId, reviewerId) {
            const _this = this, employeeAppraisals = _this.getTrueEmployeeAppraisals(validEmployeeId);
            let EA = employeeAppraisals.filter(a => a.appraisal_id == appraisal_id);
            if (EA.length >= 1) {
                [EA] = EA;
            } else {
                EA = null;
                return;
            }
            _this.current_reviewer_id = reviewerId;
            _this.selfAppraisalComment = "";
            _this.selfAppraisalRating = 0;
            _this.current_appraisal_id = EA.appraisal_id, 
            _this.currentEmployeeAppraisal = EA;
            let employeeAppraisalReview = _this.getEmployeeAppraisalReview(EA.appraisal_id, EA.employee_id, validEmployeeId);
            if (employeeAppraisalReview) {
                _this.selfAppraisalComment = employeeAppraisalReview.comment;
                _this.selfAppraisalRating = employeeAppraisalReview.rating ? parseInt(employeeAppraisalReview.rating) : 0;
            }
            _this.selfAppraisalQuestionnaires = _this.filteredQuestionnaires.map(FQ => {
                let responseObj = _this.getResponseByQuestionId(FQ.id, EA.employee_id);
                return {
                    questionnaireId: FQ.id,
                    question: FQ.question,
                    response: responseObj ? responseObj.response : '',
                    buttonText: 'Submit',
                    buttonLoading: false
                }
            });
            _this.employeeGoalsInfo = _this.getEmployeeGoalsInfo(_this.current_appraisal_id, EA.employee_id, reviewerId);
            setTimeout(() => {
                _this.printBreakdown();
            }, 1000);
        },
        async printBreakdown() {
            let _this = this;
            await _this.$htmlToPaper('printBreakdown', this.mixinPrintPortraitOptions);
        },
        launchEditResponse(commentId) {
            this.feedback_response_form.commentId = commentId;
            this.add_feedback_response_dialog = true;
        },
        submit_appraisal_feedback() {
            let _this = this, feedback_type = _this.appraisal_feedback_form.feedback_type.toLowerCase();
            if (feedback_type == 'satisfied') {
                feedback_type = 1;
            } else if (feedback_type == 'not satisfied') {
                feedback_type = 2;
            } else {
                feedback_type = 3;
            }

            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            
            _this.appraisal_feedback_form.button_text = "";
            _this.appraisal_feedback_form.button_loading = true;
            axios
            .post(`${BASE_URL}createappraisalfeedback`, {
                company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id, 
                employee_id: _this.validEmployeeId, 
                appraisal_id: _this.current_appraisal_id,
                feedback_type, 
                comment: _this.appraisal_feedback_form.comment, 
            })
            .then(function () {
                _this.fetch_company_appraisal_feedbacks();
                _this.add_appraisal_feedback_dialog = false;
                _this.appraisal_feedback_form.feedback_type = '';
                _this.appraisal_feedback_form.comment = '';
                Toast.fire({
                    icon: "success",
                    title: `Feedback submitted successfully`,
                });
            })
            .catch(function (error) {
                Toast.fire({
                    icon: "error",
                    title: `Feedback couldn't be submitted`,
                });
                console.log(error);
            })
            .then(function () {
                _this.appraisal_feedback_form.button_loading = false;
                _this.appraisal_feedback_form.button_text = "Submit Feedback";
            });
        },
        submit_feedback_response() {
            let _this = this;
            
            const Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });

            _this.feedback_response_form.button_text = "";
            _this.feedback_response_form.button_loading = true;
            axios
                .post(`${BASE_URL}createappraisalfeedbackresponse`, {
                    company_id: _this.authCompanyData.id || _this.authEmployeeDataForCompany.company_id, 
                    employee_id: _this.validEmployeeId, 
                    appraisal_id: _this.current_appraisal_id,
                    comment_id: _this.feedback_response_form.commentId, 
                    response: _this.feedback_response_form.comment, 
                })
                .then(function () {
                    _this.fetch_company_appraisal_feedbacks();
                    _this.add_feedback_response_dialog = false;
                    _this.feedback_response_form.comment = '';
                    Toast.fire({
                        icon: "success",
                        title: `Feedback reponse submitted successfully`,
                    });
                })
                .catch(function (error) {
                    Toast.fire({
                        icon: "error",
                        title: `Feedback response couldn't be submitted`,
                    });
                    console.log(error);
                })
                .then(function () {
                    _this.feedback_response_form.button_loading = false;
                    _this.feedback_response_form.button_text = "Submit Response";
                });
        },
        endAppraisal(id) {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.$swal
                .fire({
                    title: `Are you sure you want end this appraisal?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Proceed`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}endappraisal`, {
                            userappraisal_id: id
                        }).then(function () {
                            Toast.fire({
                                icon: "success",
                                title: "The appraisal was ended successfully",
                            });
                        }).catch(function (error) {
                            let errors = error.response.data.error || null;
                            Toast.fire({
                                icon: "error",
                                title: errors
                                    ? typeof errors === "string"
                                        ? errors
                                        : null
                                    : null || error.response.data.error || error.response.data.message || "The appraisal couldn't be ended",
                            });
                        }).then(function () {
                            _this.fetch_employee_appraisals();
                        });
                    }
                });
        },
        restartAppraisal(id) {
            const _this = this, Toast = _this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", _this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
                },
            });
            _this.$swal
                .fire({
                    title: `Are you sure you want restart this appraisal?`,
                    showCancelButton: true,
                    confirmButtonText: `Yes, Proceed`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        axios.post(`${BASE_URL}restartappraisal`, {
                            userappraisal_id: id
                        }).then(function () {
                            Toast.fire({
                                icon: "success",
                                title: "The appraisal was restarted successfully",
                            });
                        }).catch(function (error) {
                            let errors = error.response.data.error || null;
                            Toast.fire({
                                icon: "error",
                                title: errors
                                    ? typeof errors === "string"
                                        ? errors
                                        : null
                                    : null || error.response.data.error || error.response.data.message || "The appraisal couldn't be restarted",
                            });
                        }).then(function () {
                            _this.fetch_employee_appraisals();
                        });
                    }
                });
        }
    }
}
</script>

<style>

</style>