<template>
    <v-col cols="12">
        <v-row>
            <v-col style="background: #F8FAFC;" cols="12" class="pb-5 serve"
                :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'px-5' : 'px-100-left-stuff px-100-right-stuff'">
                <v-row class="px-2">
                    <v-col cols="12" lg="3" md="3" class="ml-auto">
                    <!-- <div>
                        <img class="w-50" src="/img/kylian-christmas-logo.png" alt="">
                    </div> -->
                    <div class="mt-5">
                        <p style="font-size: 16px; color: #52525B; font-family: Poppins !important;">
                        Manage your day-to-day business activities
                        on the go with our mobile version of the
                        Kylian ERP system.
                        </p>
                        <!-- <v-row>
                        <v-col cols="12">
                            <img src="/img/google-play.png" alt="" class="w-50">
                        </v-col>
                        <v-col cols="12">
                            <img src="/img/app-store.png" alt="" class="w-50">
                        </v-col>
                        </v-row> -->
                    </div>
                    <v-row>
                        <v-col cols="12" md="7">
                        <v-row>
                            <v-col cols="3" class="text-left">
                            <a href="https://twitter.com/KylianErp" target="_blank">
                                <img src="/img/twitter-footer.svg" alt="">
                            </a>
                            </v-col>
                            <v-col cols="3" class="text-left">
                            <a href="https://www.facebook.com/KylianERPng" target="_blank">
                                <img src="/img/facebook-footer.svg" alt="">
                            </a>
                            </v-col>
                            <v-col cols="3" class="text-left">
                            <a href="https://instagram.com/kylian_erp" target="_blank">
                                <img src="/img/instagram-footer.svg" alt="">
                            </a>
                            </v-col>
                            <v-col cols="3" class="text-left">
                            <a href="https://www.linkedin.com/company/kylianerp" target="_blank">
                                <img src="/img/linkedin-footer.svg" alt="">
                            </a>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" class="d-none">
                    <div class="mt-5 mx-5">
                        <h4 class="mb-0 pb-2"
                        style="font-size: 16px; color: #2563EB !important; font-weight: 600;">
                        COMPANY</h4>
                        <ul class="mt-3" style="list-style: none; padding-left: 0;">
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            About
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Features
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Pricing
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Career
                            </router-link>
                        </li>
                        </ul>
                    </div>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" class="d-none">
                    <div class="mt-5 mx-5">
                        <h4 class="mb-0 pb-2"
                        style="font-size: 16px; color: #2563EB !important; font-weight: 600;">
                        HELP</h4>
                        <ul class="mt-3" style="list-style: none; padding-left: 0;">
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Customer Support
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Delivery Details
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Terms & Conditions
                            </router-link>
                        </li>
                        <li class="my-2">
                            <router-link style="font-size: 16px; color: #424242;" to="/">
                            Privacy Policy
                            </router-link>
                        </li>
                        </ul>
                    </div>
                    </v-col>
                    <v-col cols="12" lg="3" md="3" class="mr-auto">
                    <div class="mt-5 mx-5">
                        <h4 class="mb-0 pb-2"
                        style="font-size: 16px; color: #2563EB !important; font-weight: 600;">
                        CONTACT</h4>
                        <ul class="mt-3" style="list-style: none; padding-left: 0;">
                        <li class="my-2">
                            <span style="font-size: 16px; color: #424242;">
                            Phone:
                            </span>
                        </li>
                        <li class="my-2">
                            <p style="font-size: 16px; color: #424242;" to="/">
                            +2348055257262
                            <br />
                            +2347080593338
                            <br />
                            </p>
                        </li>
                        <li class="my-2">
                            <span style="font-size: 16px; color: #424242;" to="/">
                            Email:
                            </span>
                        </li>
                        <li class="my-2">
                            <span style="font-size: 16px; color: #424242;" to="/">
                            kylian@kylianerp.com
                            </span>
                        </li>
                        </ul>
                    </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="px-10 pt-0" style="background: #F8FAFC;" cols="12">
                <div class="py-8" :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'md' ? 'margin: 0px;' : 'margin: 0px 60px;'" style="border-top: 2px solid #E2E8F0;">
                    <v-row>
                        <v-col cols="12" md="10" offset-md="1" class="text-center">
                            <span style="color: rgba(45, 45, 45, 0.87); font-family: Poppins !important;">&copy; Copyright {{ new Date().getFullYear() }}. All Rights Reserved by Kylian ERP</span>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {

}
</script>

<style>

</style>